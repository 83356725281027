import React from 'react';
import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import { Tooltip, Button } from 'antd';
import { Subtr } from '@/utils';
import { spaceQuotaApply } from '@/service/cooper/teamSpace';
import styles from './style.module.less';
import cooperConfirm from '@/components/common/CooperConfirm';

const cx = classBind.bind(styles);

const QuotaDetail = (props) => {
  const { className = '', config = {}} = props;
  const {
    useUp = 0,
    total = 0,
    unit = 'G',
    isOwner = false,
    applied = false,
    showBtn = true,
    bpmUrl = '',
  } = config;

  const isTransboundary = Subtr(total, useUp) < 5;

  const initToolTip = !isTransboundary || (isTransboundary && isOwner && !applied)
    ? { visible: false }
    : {};

  const junmpTo = (url) => {
    if (url) window.open(url);
  };

  const renderOperationTooltipInner = (bpmUrl) => (
    <div className={cx('quota-detail-tooltip-inner')}>
      <div className={cx('tooltip-inner-front')}>
        {intl.t('扩容申请待审核，详情请查看')}
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          junmpTo(bpmUrl);
        }}
        className={cx('tooltip-inner-end')}>
        {'BPM'}
      </div>
    </div>
  );

  const renderTooltipInner = () => (
    <div className={cx('quota-detail-tooltip-inner')}>
      <div className={cx('tooltip-inner-front')}>
        {intl.t('团队所有者可申请扩容')}
      </div>
    </div>
  );

  const renderHint = (isOwner, applied, bpmUrl) => {
    if (isOwner) {
      if (applied) {
        return renderOperationTooltipInner(bpmUrl);
      }
      return null;
    }
    return renderTooltipInner();
  };

  const qoutaSuccess = ({ approver = {}, bpmUrl }) => {
    cooperConfirm({
      type: 'success',
      title: intl.t(
        '您的申请已提交到BPM(倚天流程管理平台)中，将由 {one} 进行审批，详情请到BPM中查看',
        {
          one: approver.name || '',
        },
      ),
      okText: intl.t('去查看'),
      cancelText: intl.t('知道了'),
      onOk: () => {
        window.open(bpmUrl);
      },
    });
  };

  const requestQuota = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const {
      config: { teamId },
      success = () => { },
    } = props;
    cooperConfirm({
      className: cx('space-quota-modal'),
      type: 'warn',
      title: teamId === 0 ? intl.t('个人空间扩容') : intl.t('团队空间扩容'),
      content: (
        <div className={cx('team-quota-hint')}>
          {teamId === 0 ? (
            <p>
              {intl.t('个人空间每次可申请扩容')}
              <span className={cx('team-quota-hint-num')}>{'50G'}</span>
              {intl.t('，申请后需等待您的直属上级审核')}
            </p>
          ) : (
            <p>
              {intl.t('每个团队空间每次可申请扩容')}
              <span className={cx('team-quota-hint-num')}>{'50G'}</span>
              {intl.t('，申请后需等待您的直属上级审核')}
            </p>
          )}
        </div>
      ),
      okText: (
        <div className={cx('icon-local-container')}>
          <div
            id='icon-local-word'
            style={{
              display: 'block',
            }}
          >
            {intl.t('确认申请')}
          </div>
        </div>
      ),
      cancelText: intl.t(' 取消'),
      onOk: (cb) => {
        spaceQuotaApply({
          teamId,
          surplusQuota: 50000000000,
          reason: '',
        })
          .then((data) => {
            cb();
            success();
            qoutaSuccess(data);
          })
          .catch(() => {
            cb();
          });
      },
    });
  };

  return (
    <div
      className={cx('team-quota-container', `${isOwner && !applied && isTransboundary && showBtn
        ? cx('team-quota-container-hover')
        : ''
      }`)}
    >
      <Tooltip
        placement='bottom'
        overlay={() => renderHint(isOwner, applied, bpmUrl)}
        {...initToolTip}
      >
        <div className={cx('quota-detail', `${className}`)}>
          <div
            className={cx('quota-word')}
            title={`${useUp}/${total}${unit}`}>
            <span className={cx(`${isTransboundary ? 'red-word' : ''}`)}>{useUp}</span>
            <span>/</span>
            <span>{total}</span>
            <span>{unit}</span>
          </div>
        </div>
      </Tooltip>
      {isTransboundary && showBtn && (
        <Button
          size='small'
          type='secondary'
          onClick={requestQuota}
          className={cx('quota-btn')}
        >
          {intl.t('申请扩容')}
        </Button>
      )}
    </div>
  );
}

export default QuotaDetail;
