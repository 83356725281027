import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import SearchItem from '@/components/SearchItem';
import Title from '../Title';
import styles from './style.module.less';
import { isDiDiTenant } from '@/utils/entryEnhance'

const BigFigure = 999999;
const cx = classBind.bind(styles);

const GroupContent = ({
  metisData,
  spaceData,
  isHasFile,
  api,
  afterClickItem,
  onClickMore,
  total,
}) => {
  const getMaxLength = () => {
    let maxLength = BigFigure;
    if (isHasFile) {
      maxLength = 1;
    } else if (metisData.length > 0 && spaceData.length > 0) {
      maxLength = 4;
    }
    return maxLength;
  };

  const maxLength = getMaxLength();

  return (
    <div className={cx('group-content-wrap')}>
      <>
        {metisData.length > 0 && (
          <div className={cx('all-scope-item')}>
            <Title
              name={intl.t('知识库')}
              showMore={maxLength !== BigFigure}
              onMoreClick={() => onClickMore('metis')}
              count={total.metis}
            />
            {maxLength === BigFigure ? (
              <div className={cx('group-content-dk')}>
                {metisData.map((item, index) => (
                  <SearchItem
                    key={index}
                    data={item}
                    position={index}
                    callback={afterClickItem}
                    api={api}
                    wrapClass={cx('item-wrap')}
                  />
                ))}
              </div>
            ) : (
              <div className={cx('group-content-dk')}>
                {metisData.slice(0, maxLength).map((item, index) => (
                  <SearchItem
                    key={index}
                    position={index}
                    data={item}
                    callback={afterClickItem}
                    api={api}
                    wrapClass={cx('item-wrap')}
                  />
                ))}
              </div>
            )}
          </div>
        )}

        {spaceData.length > 0 && (
          <div className={cx('all-scope-item')}>
            <Title
              name={isDiDiTenant() ? intl.t('空间') : intl.t('团队空间')}
              showMore={maxLength !== BigFigure}
              onMoreClick={() => onClickMore('space')}
              count={total.space}
            />
            {maxLength === BigFigure ? (
              <div className={cx('group-content-space')}>
                {spaceData.map((item, index) => (
                  <SearchItem
                    key={index}
                    data={item}
                    callback={afterClickItem}
                    position={index}
                    api={api}
                    wrapClass={cx('item-wrap')}
                  />
                ))}
              </div>
            ) : (
              <div className={cx('group-content-space')}>
                {spaceData.slice(0, maxLength).map((item, index) => (
                  <SearchItem
                    key={index}
                    position={index}
                    data={item}
                    callback={afterClickItem}
                    api={api}
                    wrapClass={cx('item-wrap')}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};
export default GroupContent;
