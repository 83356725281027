const FileTableSkeleton = require('../../common/FileTableSkeleton.js')

module.exports = /* html */ `
<div class="td-skeleton-body">
  <div class="td-skeleton-bar">
    <div class="td-skeleton-bar-tabs">
      <div class="tab">
        <div class="icon td-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element">
            <span class="ant-skeleton-button ant-skeleton-button-square"></span>
          </div>
        </div>
        <div class="title td-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </div>
      <div class="tab">
        <div class="icon td-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element">
            <span class="ant-skeleton-button ant-skeleton-button-square"></span>
          </div>
        </div>
        <div class="title td-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </div>
      <div class="tab">
        <div class="icon td-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element">
            <span class="ant-skeleton-button ant-skeleton-button-square"></span>
          </div>
        </div>
        <div class="title td-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </div>
      <div class="tab">
        <div class="icon td-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element">
            <span class="ant-skeleton-button ant-skeleton-button-square"></span>
          </div>
        </div>
        <div class="title td-skeleton-button">
          <div class="ant-skeleton ant-skeleton-element ant-skeleton-active">
            <span class="ant-skeleton-button ant-skeleton-button-round"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="td-skeleton-bar-icons">
      <div class="td-skeleton-bar-icons-item">
        <i class="dk-iconfont dk-icon-zhiding4px space-operation"></i>
      </div>
      <div class="td-skeleton-bar-icons-item">
        <i class="dk-iconfont dk-icon-dongtai space-operation"></i>
      </div>
      <div class="td-skeleton-bar-icons-item">
        <i class="dk-iconfont dk-icon-duoren space-operation"></i>
      </div>
      <div class="td-skeleton-bar-icons-item">
        <i class="dk-iconfont dk-icon-huishouzhan4px space-operation"></i>
      </div>
      <div class="td-skeleton-bar-icons-item">
        <i class="dk-iconfont dk-icon-shezhi4 space-operation"></i>
      </div>
    </div>
  </div>
  <div class="td-skeleton-body-wrap">
    <div class="file-title-skeleton">
      <div class="title">
        <div class="title-inner">
          <span>全部文件</span></div>
      </div>
      <div class="action"><button type="button" class="ant-btn ant-btn-secondary"><i class="dk-iconfont dk-icon-shangchuan2"></i><span>上传</span></button><button type="button" class="ant-btn ant-btn-primary"><i class="dk-iconfont dk-icon-a-"></i><span>新建</span></button></div>
    </div>
    ${FileTableSkeleton}
  </div>
</div>
`
