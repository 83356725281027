/**
 * 提示信息，分可以跳转和不可以跳转两种形式
 */
import classBind from 'classnames/bind';
import { Tooltip } from 'antd';
import styles from './style.module.less';
import { openNewWindow } from '@/utils';

const cx = classBind.bind(styles);

const HelpLink = ({ title, className = '', jumpUrl }) => {
  return <Tooltip
    placement="top"
    title={title}
    overlayClassName={className}
    >
    <span className={cx('help-link')}>
      <i
        onClick={() => {
          jumpUrl && openNewWindow(jumpUrl);
        }}
        className={cx(`${jumpUrl ? 'dk-icon-help cursor' : 'dk-icon-shuoming3px'} `, 'dk-iconfont', 'help-tip')}
    />
    </span>
  </Tooltip>
}

export default HelpLink;
