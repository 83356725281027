import { intl } from 'di18n-react';
import classNames from 'classnames/bind';
import styles from './style.module.less';

const cx = classNames.bind(styles);

function Title(props) {
  const { name, onMoreClick, showMore, count } = props;

  return (
    <div
      className={cx('title-wrap')}
      onClick={onMoreClick}>
      <div className={cx('name')}>{name}</div>
      {showMore && (
        <div className={cx('more')}>
          <span>{intl.t('查看更多')}({ count})</span>
          <i className="dk-icon-zuojiantoujuzhong4px dk-iconfont" />
        </div>
      )}
    </div>
  );
}

export default Title;
