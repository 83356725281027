import { intl } from 'di18n-react';
import { get, post } from '@/utils/request/cooper.js';
import api from '@/utils/request/api/CooperApi';
import {
  N_TEAM,
  N_DIR,
  N_PERSONAL,
  N_TEAMROOT,
  N_ROOT,
  HIGH_SEP,
} from '@/constants/cooper';
import { spacePerson as iconPersonalSpace, spaceTeam as iconTeamSpace, folder as iconShareFolder } from '@/assets/icon/fileIcon';


function getItemIcon(item) {
  switch (item.type) {
    case N_PERSONAL:
      return iconPersonalSpace;

    case N_TEAMROOT:
      return iconTeamSpace;

    case N_TEAM:
      return iconTeamSpace;

    case N_DIR:
      return iconShareFolder;

    default:
      return iconShareFolder;
  }
}

function buildTreeData(items) {
  const root = {
    type: N_ROOT,
    id: N_ROOT,
    name: 'Cooper',
    children: [],
  };
  let depth = 1;
  let teamId = 0;
  let parent = null;
  const regx = new RegExp(`${HIGH_SEP}(.*?)${HIGH_SEP}`, 'g'); // 无搜索结果

  if (items.length === 0) return root; // 个人空间

  if (items[0].type === N_PERSONAL) {
    root.children.push(items[0]);
    items.splice(0, 1);
  } // 团队空间

  if (items.length > 0) {
    const teamRoot = {
      type: N_TEAMROOT,
      id: N_TEAMROOT,
      name: intl.t('团队空间'),
      children: [],
    };
    root.children.push(teamRoot);

    for (const item of items) {
      teamRoot.children.push(item);
    }
  }

  function _addState(root) {
    if (root.type === N_TEAM) {
      teamId = root.realId;
    }

    Object.assign(root, {
      id: root.id,
      teamId,
      name: root.name,
      path: parent ? parent.path + (parent.path ? '/' : '') + root.name : '',
      icon: getItemIcon(root),
      type: root.type,
      depth,
      hasChildren: true,
      hasOpened: root.children.length > 0,
      highLightName: root.highLightName,
      isShowOuter: (root?.relationTypeTags || []).includes('IN_OUT'),
    });

    if (root.children.length > 0) {
      root.children.forEach((child) => {
        const lastParent = parent;
        parent = root;
        depth++;

        _addState(child);

        depth--;
        parent = lastParent;
      });
    }
  }

  _addState(root);

  return root;
}

export async function searchFolderTree(keyword, spaceId) {
  const keywordNew = encodeURIComponent(keyword);
  let url = `${api.MOVE_SEARCH_TREE}?keyword=${keywordNew}`; // 移动仅支持当前空间下，因此需传一个 spaceId 过滤保留该空间下的文件夹

  if (spaceId) url += `&spaceId=${spaceId}`;
  const res = await get(url);
  return buildTreeData(res);
}
export async function doCreateFolder(name, parentId, teamId) {
  const res = await post(api.CREATE_DIRECTORY, {
    name,
    parentId,
    spaceId: teamId,
  }, {
    keepSilent: true,
  });
  return res;
}
