import { intl } from 'di18n-react';
import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Lazy } from 'swiper';
import 'swiper/swiper.less';
import 'swiper/components/navigation/navigation.less';
import 'swiper/components/lazy/lazy.less';
import { isDC } from '@/utils/index';
import { loadingPng } from '@/assets/icon/index';
import { createDocByTemplate } from '@/service/cooper/template';
import './index.less';

SwiperCore.use([Navigation, Lazy]);

class TemplatePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: props.templateIndex,
      isCreating: false,
      loading: true,
    };
    this.mySwiper = null;
  }


  setIdx = (idx) => {
    this.setState({
      currentIndex: idx,
    });
  }

  onSlideChange = (swiper) => {
    const {
      templateList,
    } = this.props;
    const { currentIndex } = this.state;
    this.setIdx(swiper.realIndex);
    this.props.setModelTitle(templateList[swiper.realIndex].name);
    if (currentIndex + 1 === swiper.realIndex) {
      // __mirrorSendEvent('PREVIEW_NEXT_CLICK');
    } else if (currentIndex - 1 === swiper.realIndex) {
      // __mirrorSendEvent('PREVIEW_PREV_CLICK');
    }
  }


  createByTemplate = (templateId) => {
    const { fileType } = this.props;
    const { isCreating } = this.state;
    if (!isCreating) {
      this.setState({
        isCreating: true,
      });

      let win = null;
      if (!isDC()) {
        win = window.open('about:blank');
        win.document.title = intl.t('创建文档中...');
      }
      createDocByTemplate({ templateId, fileType }).then((newUrl) => {
        this.setState({
          isCreating: false,
        });
        if (isDC()) {
          window.open(newUrl);
        } else {
          win.location.replace(newUrl);
        }
      }).catch(() => {
        if (win) win.close();
      });
    }
    // __mirrorSendEvent('PREVIEW_CREATE_CLICK');
    window.__OmegaEvent('ep_templatepreview_use_ck', '', {
      platform: 'new',
    });
  }


  render() {
    const { templateList, templateIndex } = this.props;
    const { currentIndex, isCreating, loading } = this.state;
    let len = templateList.length;
    return (
      <Swiper
        className='template-modal-content'
        slidesPerView={1}
        initialSlide={templateIndex}
        navigation={{
          nextEl: '.swipe-button-next',
          prevEl: '.swipe-button-prev',
        }}

        ref={(refs) => { this.mySwiper = refs; }}
        onSlideChange={this.onSlideChange}
        // onSwiper={this.initSwiper}
    >

        {
          templateList.map((img, index) => {
            return (
              <SwiperSlide
                className='preview-item'
                key={img.id + String(index)}
              >
                <img
                  src={img.imgUrl}
                  className='preview-img'
                  onLoad={() => {
                    this.setState({
                      loading: false,
                    });
                  }}
                />
                {loading && <div className='loading-div'><img
                  src={loadingPng}
                  alt='' /> {intl.t('加载中')}</div>}
              </SwiperSlide>
            );
          })
        }

        <div className='swipe-bottom-btn'>
          <div className='swipe-button-wrap'>
            <button
              className={`${
                currentIndex === 0 ? 'grey-btn' : ''
              } swipe-button-prev swipe-button`}
          >
              {intl.t('上一个')}
            </button>
            <button
              className={`${
                currentIndex === (len - 1) ? 'grey-btn' : ''
              } swipe-button-next swipe-button`}
          >
              {intl.t('下一个')}
            </button>
          </div>
          <Button
            className='use-template'
            loading={isCreating}
            onClick={() => this.createByTemplate(templateList[currentIndex].id)}
          >
            {intl.t('使用此模板')}
          </Button>
        </div>


      </Swiper>
    );
  }
}

const mapStateToProps = (state) => {
  const ca = state.Coopertemplate;
  return {
    titleText: ca.titleText,
    fileType: ca.fileType,
    allTemplateList: ca.allTemplateList,
  };
};

const mapDispatchToProps = (TemplatePreview) => {
  const {
    setModelTitle,
  } = TemplatePreview.Coopertemplate
  return {
    setModelTitle,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplatePreview);
