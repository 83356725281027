// eslint-disable-next-line max-classes-per-file
import { intl } from 'di18n-react';
import React from 'react';
import onClickOutside from 'react-onclickoutside';
import cls from 'classnames';
import { highlight } from '@/utils';
import { getTeams, getFolders } from '@/service/cooper/moveFile';
import './index.less';
import { N_TEAMROOT, N_TEAM } from '@/constants/cooper';
import Tag from '@/components/Tag';

class TreeNode extends React.Component {
  render() {
    const { node, selected, onOpen, onSelect } = this.props;
    const { children, depth } = node;
    return (
      <li>
        <div
          id={node.id}
          className={cls('tree-node', {
            selected: selected === node,
          })}
          style={{
            paddingLeft: `${(depth - 1) * 14}px`,
          }}
          onClick={() => onSelect(node)}
        >
          <span
            className='triangle-container'
            onClick={(e) => {
              e.stopPropagation();
              onOpen(node);
            }}
          >
            <i
              className='dk-iconfont dk-icon-shouqi triangle'
              style={{
                opacity: node.hasChildren ? 1 : 0,
                transform: node.hasOpened ? 'rotate(90deg)' : 'none',
              }}
            />
          </span>
          <img
            src={node.icon}
            alt='' />
          <span>{highlight(node.highLightName || node.name)}</span>
          {
            node?.isShowOuter && <Tag
              type='out-yellow'
              text={intl.t('外部')}/>
          }
        </div>
        {!!children && children.length > 0 && (
          <ul
            style={{
              display: node.hasOpened ? 'block' : 'none',
            }}
          >
            {node.children.map((ch) => (
              <TreeNode
                {...this.props}
                key={ch.id}
                node={ch} />
            ))}
          </ul>
        )}
      </li>
    );
  }
}

class CTree extends React.Component {
  static defaultProps = {
    tree: {},
    hideIgnoreId: '',
    onSelect: () => {},
    onHide: () => {},
  };

  state = {
    tree: this.props.tree,
    selected: null,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.tree !== this.props.tree) {
      this.setState({
        tree: nextProps.tree,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.tree !== this.props.tree
      || nextState.selected !== this.state.selected
    );
  }

  handleClickOutside = (e) => {
    if (e.target.dataset.onclickoutside !== 'ignore') {
      this.props.onHide();
    }
  };

  handleOpen = async (node) => {
    // 1. 切换开闭状态
    node.hasOpened = !node.hasOpened; // 2. 加载子节点

    if (node.hasChildren && node.children.length === 0) {
      let res = [];

      if (node.type === N_TEAMROOT) {
        res = await getTeams();
      } else {
        let { id, teamId } = node;

        if (node.type === N_TEAM) {
          id = 0;
          teamId = node.id;
        }

        res = await getFolders(id, teamId);
      }

      if (res.length === 0) {
        node.hasChildren = false;
      } else {
        res.forEach((fold) => {
          fold.depth = node.depth + 1;
          fold.path = `${node.path}/${fold.name}`;
        });
        node.children = res;
      }
    }

    this.forceUpdate();
  };

  handleSelect = (node) => {
    if (node.type !== N_TEAMROOT) {
      this.setState({
        selected: node,
      });
      this.props.onSelect(node);
    }
  };

  render() {
    const { tree, selected } = this.state;
    const children = tree.children || [];
    return (
      <div className='c-tree'>
        {children.length === 0 && (
          <p>{intl.t('没有找到匹配结果，尝试其他关键词进行搜索')}</p>
        )}
        <ul>
          {children.map((ch) => (
            <TreeNode
              key={ch.id}
              selected={selected}
              node={ch}
              onOpen={this.handleOpen}
              onSelect={this.handleSelect}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export default onClickOutside(CTree);
