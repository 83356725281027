module.exports = `
body {
  height: 100%;
  margin: 0px;
  font-size: 14px;
  line-height: 22px;
  overflow: auto;
}

.home-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 40px
}

.home-wrap .home-main {
  min-width: 667px
}

.generic .home-wrap-os-flag,
.linux .home-wrap-os-flag,
.mac .home-wrap-os-flag {
  overflow-y: auto
}

.right {
  width: 100%;
  min-width: 850px;
}

.tab-top-title {
  flex: 1
}

.tab-top-title .ant-tabs-content-top {
  height: 100%
}

.tab-top-title .ant-tabs-nav {
  margin-bottom: 15px
}

.tab-top-title .ant-tabs-ink-bar {
  display: none !important
}

.tab-top-title .ant-tabs-tab-active {
  position: relative
}

.tab-top-title .ant-tabs-tab-active:before {
  content: "";
  width: 24px;
  height: 2px;
  background-color: #047ffe;
  left: calc(50% - 12px);
  bottom: 0;
  z-index: 100;
  position: absolute
}

.tab-top-large .ant-tabs-tab {
  font-size: 16px !important;
  line-height: 24px !important;
  padding-top: 4px !important;
  padding-bottom: 6px !important
}

.icon-gengduo1 {
  color: rgba(34, 42, 53, .5)
}

.v3-operate-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px
}

.v3-operate-menu:hover {
  background: rgba(0, 0, 0, .05);
  border-radius: 4px
}

.v3-quick-access {
  min-width: 667px;
  margin: 0 auto 12px;
  padding: 0 32px
}

.v3-quick-access-title {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  font-weight: 400;
  color: #222a35;
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  height: 24px;
  background-color: #fff;
  display: block;
  margin-bottom: 0;
  padding: 0 0 12px;
  box-sizing: content-box
}

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.recent-activity {
  margin: 0 auto;
  min-width: 667px
}

.recent-activity .ant-tabs-nav {
  padding: 0 32px 2px;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 2
}

.file-ellipsis {
  padding-right: 4px;
  display: flex;
  color: #2f343c;
  align-items: center;
  overflow: hidden;
  height: 22px;
  padding-left: 4px;
  width: 100%;
  cursor: pointer
}

.file-ellipsis .file-ellipsis-inner-span {
  white-space: nowrap;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  padding: 0 2px;
  height: 22px;
  line-height: 22px;
  color: #222a35
}

.file-ellipsis .link-tag {
  font-family: PingFangSC-Medium;
  display: inline-block;
  background: rgba(4, 127, 254, .1);
  border-radius: 4px;
  text-align: center;
  padding: 0 8px;
  color: #047ffe;
  font-size: 22px;
  -webkit-transform: scale(.5);
  transform: scale(.5);
  box-sizing: content-box;
  height: 36px;
  line-height: 36px;
  margin-left: -22px;
  margin-right: -26px
}

.file-name-list {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  margin-right: 4px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: left center;
  object-position: left center;
  overflow: hidden;
  display: inline-block;
  line-height: 1
}

.file-name-list svg {
  max-width: 100%;
  max-height: 100%
}

.cooper-list-content {
  min-width: 667px;
  margin: -8px auto 0
}

.cooper-list-content .folder-tree {
  height: 100%;
  width: 100%;
  position: relative
}

.cooper-list-content .tb-header {
  font-size: 14px;
  font-weight: 500;
  color: #656a72;
  position: -webkit-sticky;
  position: sticky;
  top: 36px;
  background-color: #fff;
  padding-left: 29px;
  padding-right: 32px;
  z-index: 4
}

.cooper-list-content .tb-header .tb-header-div {
  height: 36px !important;
  line-height: 36px !important;
  border-bottom: 1px solid rgba(34, 42, 53, .08);
  position: relative
}

.cooper-list-content .tb-header .tb-header-div .file-name {
  position: relative;
  overflow: visible;
  display: flex;
  align-items: center
}

.cooper-list-content .tb-header .tb-header-div .file-handle {
  cursor: pointer;
  height: 26px;
  padding-left: 4px;
  line-height: 24px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center
}

.cooper-list-content .tb-header .tb-header-div .file-checked {
  display: flex;
  align-items: center;
  background-color: rgba(4, 127, 254, .1);
  color: #047ffe
}

.cooper-list-content .tb-header .tb-header-div .file-checked .icon-shaixuan2 {
  padding: 2px 2px 0;
  font-size: 14px
}

.cooper-list-content .tb-body>li,
.cooper-list-content .tb-header>.tb-header-div {
  height: 48px;
  line-height: 48px;
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: rgba(34, 42, 53, .7)
}

.cooper-list-content .tb-body>li>span,
.cooper-list-content .tb-header>.tb-header-div>span {
  display: inline-block
}

.cooper-list-content .tb-body>li>.file-name,
.cooper-list-content .tb-header>.tb-header-div>.file-name {
  max-width: none;
  min-width: 250px;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 3;
  cursor: pointer;
  padding-right: 12px
}

.cooper-list-content .tb-body>li .file-content-box,
.cooper-list-content .tb-header>.tb-header-div .file-content-box {
  margin-right: 12px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

.cooper-list-content .tb-body>li .file-owner-content-box,
.cooper-list-content .tb-header>.tb-header-div .file-owner-content-box {
  padding-left: 4px
}

.cooper-list-content .tb-body>li>.file-address-,
.cooper-list-content .tb-header>.tb-header-div>.file-address- {
  height: 100%;
  width: 300px;
  min-width: 200px;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

.cooper-list-content .tb-body>li>.file-address- .file-content-box,
.cooper-list-content .tb-header>.tb-header-div>.file-address- .file-content-box {
  padding: 0 2px;
  height: 22px;
  border-radius: 4px;
  line-height: 22px;
  cursor: pointer;
  width: auto
}

.cooper-list-content .tb-body>li>.file-owner,
.cooper-list-content .tb-body>li>.file-time,
.cooper-list-content .tb-header>.tb-header-div>.file-owner,
.cooper-list-content .tb-header>.tb-header-div>.file-time {
  height: 100%;
  width: 130px;
  min-width: 130px;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 12px
}

.cooper-list-content .tb-body>li>.file-owner .file-content-box,
.cooper-list-content .tb-body>li>.file-time .file-content-box,
.cooper-list-content .tb-header>.tb-header-div>.file-owner .file-content-box,
.cooper-list-content .tb-header>.tb-header-div>.file-time .file-content-box {
  cursor: default
}

.cooper-list-content .tb-body>li .file-owner,
.cooper-list-content .tb-header>.tb-header-div .file-owner {
  width: 150px;
  min-width: 150px
}

.cooper-list-content .tb-body>li>.file-operate,
.cooper-list-content .tb-header>.tb-header-div>.file-operate {
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center
}

.cooper-list-content .tb-body>li>.file-owner,
.cooper-list-content .tb-header>.tb-header-div>.file-owner {
  min-width: 150px;
  overflow: hidden
}

.cooper-list-content .tb-body>li>.file-address-,
.cooper-list-content .tb-header>.tb-header-div>.file-address- {
  min-width: 130px
}

.cooper-list-content .tb-body {
  padding-left: 27px;
  padding-right: 28px;
  padding-top: 8px
}

.cooper-list-content .tb-body>li {
  padding-left: 4px;
  padding-right: 4px
}

.cooper-list-content .tb-body>li .file-name {
  color: #222a35
}

.cooper-recent-table-loading {
  position: relative;
  margin-top: 16px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: rgba(34, 42, 53, .7)
}

.v3-card {
  flex: 1;
  height: 46px;
  border-radius: 4px;
  margin: 0 12px 10px 0;
  border: 1px solid #f2f3f3;
  background-color: #fff;
  padding: 12px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  width: calc(25% - 9px);
  min-width: calc(25% - 9px);
  max-width: calc(25% - 9px)
}

.v3-card .v3-card-img {
  width: 24px !important;
  height: 24px !important;
  overflow: hidden;
  display: inline-block;
  margin: 0 8px 0 4px
}

.v3-card .v3-card-img svg {
  max-width: 100%;
  max-height: 100%
}

.v3-card>img {
  width: 24px;
  height: 24px;
  -o-object-fit: cover;
  object-fit: cover;
  margin: 0 8px 0 4px
}

.v3-card .v3-card-text- {
  display: inline-block;
  vertical-align: middle;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #222a35
}

.v3-card .operate-tag {
  width: 18px;
  text-align: center;
  display: none
}

.v3-card:before {
  display: none;
  font-family: "dk-iconfont" !important;
  content: "\e6af";
  color: #999;
  position: absolute;
  left: 3px;
  top: center;
  font-size: 12px
}

.v3-card:hover {
  border: 1px solid #047ffe
}

.v3-card:hover .operate-tag {
  display: inline-block
}

.v3-card:hover:before {
  display: block
}

.global-search-wrap {
  height: 32px;
  line-height: 32px;
  width: 100%;
  position: relative
}

.global-search-wrap .search-before {
  display: flex;
  align-items: center;
  background: none;
  height: 24px;
  line-height: 24px;
  color: rgba(34, 42, 53, .3);
  border: none;
  padding-left: 3px;
  font-size: 14px
}

.global-search-wrap .search-tag {
  margin-left: 8px;
  padding: 0 4px 0 8px;
  border-radius: 3px;
  background: #EBEEF1;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
}

.global-search-wrap .search-icon-btn {
  display: block;
  align-items: center;
  line-height: 14px;
  font-size: 12px;
  border: 1px solid rgba(34, 42, 53, .2);
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 4px;
  color: rgba(0, 0, 0, .5);
  cursor: pointer
}

.global-search-wrap .search-icon-btn .search-text {
  margin-right: 5px
}

.global-search-wrap .ant-input-affix-wrapper {
  height: 32px
}

.global-search-wrap .ant-input-group-addon {
  border-radius: 4px
}

.cooper-notify {
  display: inline-block;
  position: relative;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
}

.cooper-notify .dk-icon-zhanneixin {
  font-size: 16px;
  color: #6a707c;
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.cooper-notify-count {
  position: absolute;
  top: -6px;
  left: 10px;
  background-color: #ff563a;
  border: 1px solid #fff;
  color: #fff;
  display: inline-block;
  min-width: 16px;
  height: 16px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  padding: 0 4px;
  z-index: 100
}

.notify-list-small {
  line-height: 40px;
  text-align: center;
  margin-right: 0;
  margin-top: 5px;
}

.notify-list-small .dk-icon-zhanneixin {
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 40px
}

.icon-user {
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  text-align: center;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #6a707c;
  margin-right: 18px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50%;
  border-radius: 50%;
  background-image: url("https://img-hxy021.didistatic.com/static/iportal/upload/d0aba0cdadd283b84859bb51bd5995d3.jpg");
}

.icon-user-small {
  background-image: url("https://img-hxy021.didistatic.com/static/iportal/upload/d0aba0cdadd283b84859bb51bd5995d3.jpg");
  cursor: pointer;
  height: 28px;
  line-height: 40px;
  text-align: center;
  width: 22px;
  border-radius: 4px;
  justify-content: center;
  font-size: 18px;
  color: #6a707c;
  margin-right: 0;
  margin-top: 5px;
  border-radius: 50%;
  background-size: contain;
}

.icon-shiyanshi-small {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #6A707C;
  margin-right: 0;
  font-size: 18px;
}

.icon-shiyanshi {
  cursor: pointer;
  height: 24px;
  line-height: 24px;
  text-align: center;
  width: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #6A707C;
}

.tip-btn {
  position: relative;
  background: rgba(255, 165, 12, .1);
  border-radius: 4px;
  border: .5px solid rgba(255, 165, 12, .18);
  font-family: PingFangSC-Medium;
  font-size: 11px;
  font-weight: 400;
  color: #faa006;
  display: flex;
  align-items: center;
  height: 24px;
  line-height: 24px;
  width: 70px;
  text-align: center;
  cursor: auto;
  overflow: hidden;
}

.tip-btn-long {
  margin-left: 18px;
}

.tip-btn .cooper-tip-name {
  font-size: 22px;
  -webkit-transform: scale(.5);
  transform: scale(.5);
  width: 100%;
  white-space: nowrap;
  -webkit-transform-origin: left;
  transform-origin: left;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis
}

.tip-btn>span {
  height: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center
}

.tip-btn>span>img {
  width: 12px;
  height: 12px;
  margin-left: 2px
}

.logo .logo-large {
  width: 158px;
  margin: 10px 16px 0;
  padding: 0 12px
}

.logo .logo-small {
  width: 36px;
  margin: 10px 8px;
  position: relative;
  left: 4px
}

.aside-large-wrap {
  width: 240px;
  opacity: 1
}

.aside-small-wrap {
  width: 64px;
  opacity: 1
}

.aside {
  display: flex;
  flex-direction: column;
  padding-top: 10px
}

.aside .aside-content {
  position: relative;
  margin-top: 20px;
  flex: 1
}

.aside .item-name {
  width: 100%;
  color: #222a35;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px
}

.aside-operate .item-name-normal {
  padding: 12px 8px 12px 12px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer
}

.aside .item-container {
  display: flex;
  align-items: center;
  width: auto
}

.aside .item-small-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  flex-direction: column
}

.aside .item-small-container>i {
  padding-top: 4px
}

.aside .item-small-container>span {
  width: 200%;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 4px
}

.aside .aside-icon {
  color: #6a707c
}

.aside .item-text {
  padding-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.aside .item-name-active {
  color: #047ffe;
  background-color: #e5f2fe;
  font-weight: 500
}

.aside .item-name-active .aside-icon {
  color: #047ffe
}

.left-action {
  width: 240px;
  height: 56px;
  align-items: center;
  z-index: 2
}

.left-action,
.left-action-small {
  background-color: #f7f9fa;
  display: flex;
  justify-content: center;
}

.left-action-small {
  width: 64px;
  height: 175px;
  flex-direction: column;
  padding: 19px 0;
}

.icon-aside-large {
  font-size: 18px;
  line-height: 20px;
}

.space-content-wrap {
  width: 100%;
  font-size: 13px;
  line-height: 21px;
}

.space-content-wrap .space-item {
  width: 100%;
  color: #222a35;
  margin-bottom: 2px;
}

.space-content-wrap .space-item .wrap {
  width: 100%
}

.space-content-wrap .space-item .space-item-normal {
  padding: 7px 12px;
  width: 100%;
  display: inline-block;
  border-radius: 4px;
}

.space-content-wrap .space-item .space-item-normal .content-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.space-content-wrap .space-item .space-item-normal .content-wrap .left-wrap-Fig {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.space-content-wrap .space-item .space-item-normal .content-wrap .left-wrap-Fig .icon-dian {
  font-size: 20px;
  line-height: 14px;
  color: #d8dee3
}

.space-content-wrap .space-item .space-item-normal .content-wrap .left-wrap-Fig .item-text {
  padding-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.space-content-wrap .space-item .space-item-normal .content-wrap .icon-pin {
  display: none;
  font-size: 16px;
  line-height: 16px;
  padding: 2px;
  color: #6a707c;
  border-radius: 4px;
}

.large-wrap .aside-large {
  width: 240px;
}

.large-wrap .aside-large .item-name-normal {
  padding: 7px 24px;
}

.large-wrap .aside-large .item-name {
  height: 34px;
}

.large-wrap .aside-large .split-line {
  width: 100%;
  height: 1px;
  background-color: #ebeef1;
  margin-top: 4px;
  margin-bottom: 6px;
}

.large-wrap .pine-space {
  width: 100%;
  padding: 0 8px
}

.large-wrap .pine-space .space-item-nav {
  padding-left: 46px !important
}

.aside-small-box {
  width: 64px;
  flex: 1
}

.aside-small {
  height: 100%;
  /* padding:10px 2px 120px  */
  /* NOTE 此120在React中修复了一些未知的样式问题，此处不需要 */
  padding: 10px 2px 0px !important;
}

.item-name {
  margin-bottom: 2px;
}

/* NOTE 这两个item-name为人工修订 */
.item-name-6 {
  margin-bottom: 6px !important
}

.aside-small .item-name-normal {
  /* NOTE 此样式在工具生成后人工修订 */
  height: auto !important;
  padding: 4px 0 !important;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.small-name {
  font-size: 22px !important;
  -webkit-transform: scale(.5);
  transform: scale(.5)
}

.header-top-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 540px;
  flex-grow: 0;
  flex-shrink: 0
}

@media screen and (max-width: 1200px) {
  .header-top-left{
    flex-basis: 445px;
  }
}

@media screen and (max-width: 1020px) {
  .header-top-left{
    flex-basis: 360px;
  }
}

.header-warp {
  padding: 0 32px;
  margin: 0 auto;
  min-width: 667px
}

.header-warp .header-top {
  margin: 0 auto;
  min-width: 667px;
  height: 72px;
  /* line-height:72px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* font-size: 0;*/ /* inline-block不换行时不需要*/
}

.header-warp .header-top .title-opt {
  min-width: 192px;
  text-align: right;
  flex: 1;
}

.header-warp .header-top .title-opt>button {
  margin-left: 12px;
  /* font-size: 0;*/ /* inline-block不换行时不需要*/
}

.header-warp .header-top .title-opt span {
  font-size: 14px;
}

.header-warp .cooper-customer-service {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-left: 20px;
}

.header-warp .customer-service-bar {
  line-height: 18px;
  height: 18px;
  margin-right: 10px;
  border-left: 1px solid #e8e9ea;
}

.header-warp .cooper-helper {
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-warp .feedback-with-popup {
  margin-left: 10px;
}

.header-warp .feedback .content {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-align: center;
}

.header-warp .dk-icon-gongnengshangxin {
  font-size: 18px !important; 
}

.header-warp .dk-icon-lianxikefu {
  font-size: 18px !important; 
}

.space-skeleton-header-search .cooper-customer-service {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-left: 20px;
}

.space-skeleton-header-search .customer-service-bar {
  line-height: 18px;
  height: 18px;
  margin-right: 10px;
  border-left: 1px solid #e8e9ea;
}

.space-skeleton-header-search .cooper-helper {
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-skeleton-header-search .feedback-with-popup {
  margin-left: 10px;
}

.space-skeleton-header-search .feedback .content {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-align: center;
}

.space-skeleton-header-search .dk-icon-gongnengshangxin {
  font-size: 18px !important; 
}

.space-skeleton-header-search .dk-icon-lianxikefu {
  font-size: 18px !important; 
}

.layout,
.layout .left-wrap {
  display: flex;
  height: 100%;
  position: relative
}

.layout .left-wrap {
  z-index: 106
}

.layout .left-wrap .left {
  height: 100%;
  background-color: #f7f9fa;
  position: relative;
  display: flex;
  transition: all 2s ease;
  overflow-x: hidden;
  overflow-y: overlay;
  overflow-y: auto
}

.layout .left-wrap .aside-btn-handle {
  width: 20px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 0;
  background-color: transparent
}

.layout .right {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column
}

.layout .right .body-wrap {
  flex: 1;
  overflow-y: hidden;
  min-width: 737px
}

.aside-operate {
  width: 14px;
  height: 56px;
  border-radius: 64px;
  display: flex;
  border: 1px solid #ebeef1;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 69px;
  right: 8px;
  background-color: #fff;
  z-index: 999;
  cursor: pointer
}

.aside-operate .operate-icon {
  color: rgba(34, 42, 53, .3);
  font-size: 14px
}

.v3-cooper-helper {
  cursor: pointer;
  height: 42px;
  line-height: 42px;
  text-align: center;
  width: 42px;
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebeef1;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, .1)
}

.v3-cooper-helper .dk-icon-a-bangzhuzhongxin4px {
  font-size: 18px;
  color: #6a707c
}

.global-utils-warp {
  position: fixed;
  right: 24px;
  bottom: 40px;
  z-index: 1000
}

.cooper-upload-list-up .cooper-upload-list-close {
  display: none
}

.cooper-upload-list-up .drag-modal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  font-size: 36px;
  display: none;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 4px dashed #fff
}

.cooper-upload-list-up .cooper-upload-list {
  position: fixed;
  bottom: 12px;
  right: 52px;
  background-color: #fff;
  width: 480px;
  border: 1px solid rgba(148, 160, 176, .14);
  box-sizing: border-box;
  box-shadow: 0 4px 16px 0 rgba(47, 52, 60, .2);
  border-radius: 4px;
  z-index: 20
}

.tip-btn-small {
  cursor: auto;
  width: 28px;
  height: 28px;
  background: rgba(255, 165, 12, .1);
  border: .7px solid rgba(255, 165, 12, .18);
  border-radius: 5.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.tip-btn-small .cooper-tip-name {
  display: none
}

.tip-btn-small>span img {
  width: 17px;
  height: 17px;
  margin: 0;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  line-height: 1.15
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button
}

body,
html {
  width: 100%;
  height: 100%
}

*,
:after,
:before {
  box-sizing: border-box
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

body {
  margin: 0;
  color: #222a35;
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Tahoma, Arial, "PingFang SC", "Microsoft YaHei", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum"
}

input[type=number],
input[type=password],
input[type=text],
textarea {
  -webkit-appearance: none
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1em
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0
}

a {
  color: #047ffe;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color .3s;
  -webkit-text-decoration-skip: objects
}

img {
  vertical-align: middle;
  border-style: none
}

[role=button],
a,
area,
button,
input:not([type=range]),
label,
select,
summary,
textarea {
  touch-action: manipulation
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.anticon>* {
  line-height: 1
}

.anticon svg {
  display: inline-block
}

.anticon-spin,
.anticon-spin:before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s linear infinite;
  animation: loadingCircle 1s linear infinite
}

html {
  -wave-shadow-color: #047ffe;
  -bar: 0
}

.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
  cursor: pointer;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: #222a35;
  border: 1px solid rgba(34, 42, 53, .08);
  background: #fff
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0
}

.ant-btn>span {
  display: inline-block
}

.ant-btn-primary {
  color: #fff;
  border-color: #047ffe;
  background: #047ffe;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, .045)
}

.ant-btn:before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: .35;
  transition: opacity .2s;
  content: "";
  pointer-events: none
}

.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #222a35;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid rgba(34, 42, 53, .08);
  border-radius: 2px;
  transition: all .3s;
  display: inline-flex
}

.ant-input-affix-wrapper>input.ant-input {
  padding: 0;
  border: none;
  outline: none
}

.ant-input-affix-wrapper:before {
  width: 0;
  visibility: hidden;
  content: "\a0"
}

.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center
}

.ant-input-suffix {
  margin-left: 4px
}

.ant-input-clear-icon,
.anticon.ant-input-clear-icon {
  margin: 0;
  color: rgba(0, 0, 0, .25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color .3s
}

.ant-input-clear-icon-hidden,
.anticon.ant-input-clear-icon-hidden {
  visibility: hidden
}

.ant-input {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: #222a35;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid rgba(34, 42, 53, .08);
  border-radius: 2px;
  transition: all .3s
}

.ant-input::-webkit-input-placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  user-select: none
}

.ant-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis
}

.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #222a35;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0
}

.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group>.ant-input {
  display: table-cell
}

.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle
}

.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit
}

.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: #222a35;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid rgba(34, 42, 53, .08);
  border-radius: 2px;
  transition: all .3s
}

.ant-input-group-addon:first-child,
.ant-input-group-addon:first-child .ant-select .ant-select-selector,
.ant-input-group>.ant-input:first-child,
.ant-input-group>.ant-input:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.ant-input-group>.ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.ant-input-group>.ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.ant-input-group-addon:first-child {
  border-right: 0
}

.ant-input-group-addon:last-child {
  border-left: 0
}

.ant-input-group-addon:last-child,
.ant-input-group-addon:last-child .ant-select .ant-select-selector,
.ant-input-group>.ant-input:last-child,
.ant-input-group>.ant-input:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top
}

.ant-skeleton {
  display: table;
  width: 100%
}

.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton-active .ant-skeleton-image,
.ant-skeleton-active .ant-skeleton-input,
.ant-skeleton-active .ant-skeleton-paragraph>li,
.ant-skeleton-active .ant-skeleton-title {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background: transparent
}

@-webkit-keyframes ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%)
  }

  to {
    transform: translateX(37.5%)
  }
}

@keyframes ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%)
  }

  to {
    transform: translateX(37.5%)
  }
}

.ant-skeleton-active .ant-skeleton-avatar:after,
.ant-skeleton-active .ant-skeleton-button:after,
.ant-skeleton-active .ant-skeleton-image:after,
.ant-skeleton-active .ant-skeleton-input:after,
.ant-skeleton-active .ant-skeleton-paragraph>li:after,
.ant-skeleton-active .ant-skeleton-title:after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, hsla(0, 0%, 74.5%, .2) 25%, hsla(0, 0%, 50.6%, .24) 37%, hsla(0, 0%, 74.5%, .2) 63%);
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: ""
}

.ant-skeleton-element {
  display: inline-block;
  width: auto
}

.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: hsla(0, 0%, 74.5%, .2);
  border-radius: 2px;
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px
}

.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #222a35;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: absolute;
  display: none;
  color: #047ffe;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: -webkit-transform .3s cubic-bezier(.78, .14, .15, .86);
  transition: transform .3s cubic-bezier(.78, .14, .15, .86);
  transition: transform .3s cubic-bezier(.78, .14, .15, .86), -webkit-transform .3s cubic-bezier(.78, .14, .15, .86)
}

.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1
}

.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em
}

.ant-tabs-bottom,
.ant-tabs-top {
  flex-direction: column
}

.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav,
.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav {
  margin: 0 0 16px
}

.ant-tabs-bottom>.ant-tabs-nav:before,
.ant-tabs-bottom>div>.ant-tabs-nav:before,
.ant-tabs-top>.ant-tabs-nav:before,
.ant-tabs-top>div>.ant-tabs-nav:before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid rgba(34, 42, 53, .08);
  content: ""
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width .3s, left .3s, right .3s
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap:before {
  top: 0;
  bottom: 0;
  width: 30px
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap:before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, .08)
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap:after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, .08)
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>.ant-tabs-nav:before,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>div>.ant-tabs-nav:before {
  bottom: 0
}

.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #222a35;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  display: flex
}

.ant-tabs>.ant-tabs-nav,
.ant-tabs>div>.ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transform: translate(0);
  transform: translate(0)
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap:before {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity .3s;
  content: "";
  pointer-events: none
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-more:after,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-more:after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  content: ""
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #047ffe;
  pointer-events: none
}

.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  font-size: 16px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer
}

.ant-tabs-tab-btn,
.ant-tabs-tab-remove {
  outline: none;
  transition: all .3s
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #222a35;
  text-shadow: 0 0 .25px currentcolor
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 10px 0 32px
}

.ant-tabs-content {
  display: flex;
  width: 100%
}

.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0
}

.ant-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: none
}

html body {
  height: 100%;
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  overflow: auto;
  color: #222a35;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Tahoma, Arial, "PingFang SC", "Microsoft YaHei", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased
}

html body p,
html body ul {
  padding: 0;
  margin: 0
}

html body li {
  list-style: none
}

html body a {
  text-decoration: none
}

html body a,
html body a:hover {
  color: #222a35
}

html body ul {
  -webkit-padding-start: 0;
  padding-inline-start: 0
}

html body .dk-iconfont {
  font-weight: 400;
  line-height: 16px
}

html body input {
  caret-color: #047ffe
}

html body .ant-input {
  border-radius: 0
}

html body #root {
  height: 100%
}

html body #root-skeleton {
  height: 100%
}

.generic .os-scrollbar,
.linux .os-scrollbar,
.mac .os-scrollbar {
  overflow-y: overlay;
  overflow-y: auto
}

html body .ant-tabs {
  color: rgba(34, 42, 53, .7);
  position: relative
}

html body .ant-tabs .ant-tabs-nav {
  background: #fff
}

html body .ant-tabs .ant-tabs-nav:before {
  border-bottom: none
}

html body .ant-tabs .ant-tabs-tab {
  font-size: 16px;
  line-height: 38px;
  padding: 0
}

html body .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500
}

html body .ant-tabs .ant-tabs-ink-bar {
  background-color: #047ffe;
  height: 2px !important;
  border-radius: 8px
}

html body .ant-btn {
  border-radius: 3px;
  line-height: normal
}

html body .ant-btn>i {
  margin-right: 8px !important
}

html body .ant-btn-primary {
  background-color: #047ffe;
  border: 1px solid #047ffe;
  color: #fff;
  box-shadow: none
}

html body .ant-btn-secondary {
  background-color: #fff;
  border: 1px solid #047ffe;
  color: #047ffe
}

html body .ant-input-affix-wrapper {
  border-radius: 6px
}

html body .ant-input-clear-icon,
html body .anticon .ant-input-clear-icon {
  color: rgba(34, 42, 53, .3);
  font-size: 14px
}

html body .ant-input-group-wrapper {
  border: 1px solid rgba(34, 42, 53, .1);
  border-radius: 4px
}

html body .ant-input-group-wrapper .ant-input-group-addon {
  background: #fff;
  border: none;
  padding: 0 8px 0 9px
}

html body .ant-input-group-wrapper .ant-input-affix-wrapper {
  padding: 0;
  border: none;
  box-shadow: none
}

html body .ant-input {
  font-size: 14px;
  height: 30px
}

html .ant-input::-webkit-input-placeholder {
  color: rgba(34, 42, 53, .5)
}

html .ant-input::placeholder {
  color: rgba(34, 42, 53, .5)
}

/* Left Skeleton (in .aside-content for insurance) */
.aside-content .aside-skeleton-box {
  width: 240px;
  padding: 0px 8px;
}

.aside-content .aside-skeleton-box .aside-skeleton-button * {
  min-width: auto !important;
  width: 100% !important;
  height: 100% !important;
}

.aside-content .aside-skeleton-box .aside-skeleton-box-item {
  width: 100%;
  height: 36px;
  position: relative;
}

.aside-content .aside-skeleton-box .aside-skeleton-box-item .aside-skeleton-logo {
  position: absolute;
  top: 10px;
  left: 24px;
  width: 16px;
  height: 16px;
}

.aside-content .aside-skeleton-box .aside-skeleton-box-item .aside-skeleton-title {
  position: absolute;
  top: 11px;
  left: 52px;
  width: 48px;
  height: 14px;
}

.aside-content .aside-skeleton-box .aside-skeleton-space {
  width: 100%;
}

.aside-content .aside-skeleton-box .aside-skeleton-space .aside-skeleton-space-item {
  width: 100%;
  height: 35px;
  position: relative;
}

.aside-content .aside-skeleton-box .aside-skeleton-space .aside-skeleton-space-item .aside-skeleton-symbol {
  display: block;
  position: absolute;
  top: 15.5px;
  left: 52px;
  width: 4px;
  height: 4px;
}

.aside-content .aside-skeleton-box .aside-skeleton-space .aside-skeleton-space-item .aside-skeleton-title {
  display: block;
  position: absolute;
  top: 11px;
  left: 64px;
  width: 88px;
  height: 13px;
}
`
