import { intl } from 'di18n-react';
import cls from 'classnames';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);
// 页面上的提示，可操作关闭
const Tips = ({ text, isShow, onClose, overlayClassName }) => {
  return (
    <>
      {
        isShow && <span
          className={cls(cx('tips-wrap'), overlayClassName)}>
          <span className={cx('text')}>{intl.t(text)}</span>
          <i
            onClick={(e) => {
              onClose && onClose();
              e.stopPropagation();
            }}
            className={cx(
              'dk-iconfont',
              'dk-icon-chouti-guanbi',
              'remove-icon',
            )} />
        </span >
      }
    </>
  );
};

export default Tips;
