import { intl } from 'di18n-react';
import React from 'react';
import { message, Tag, Select } from 'antd';
import { debounce } from 'lodash-es';
import classBind from 'classnames/bind';
import InfiniteScroll from 'react-infinite-scroller';
import onClickOutside from 'react-onclickoutside';
import defaultTeamAvatar from './icon/icon_morentouxiang.svg';
import defaultAvatar from './icon/default-avatar.jpg';
import iconSearch from './icon/icon_sousuo.svg';
import PersonCard from '@/components/common/PersonCard';
import Loading from '@/components/SpinRender';
import styles from './style.module.less';
import { shortenDep, getGroupType } from '@/utils/cooperutils';


const cx = classBind.bind(styles);
const ENUM_TABS = {
  ALL: 0,
  USER: 1,
  GROUP: 2,
};

const SCROLL_TAB = [
  {
    type: ENUM_TABS.ALL,
    value: () => intl.t('全部'),
  },
  {
    type: ENUM_TABS.USER,
    value: () => intl.t('用户'),
  },
  {
    type: ENUM_TABS.GROUP,
    value: () => intl.t('群组'),
  },
];

const MoreMember = (props) => {
  const { value, onChoose } = props;
  return (
    <div
      className={cx('more-member')}
      onClick={() => onChoose()}>
      <img
        src={iconSearch}
        className={cx('more-member-search')} />
      <span className={cx('more-member-text')}>{intl.t('查看更多{value}', { value })}</span>
      <span className={cx('arrow-right-grey')} />
    </div>
  );
};


class MemberSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      source: [],
      chooseListType: 0,
      chooseList: props.value,
      pending: false,
      hasMore: false,
    };
  }

  toggleTab = (type) => {
    this.handleChooseTab(type);
    const { onClickTab } = this.props;
    if (typeof onClickTab === 'function') {
      onClickTab(type);
    }
  }

  handleChooseTab = (type) => {
    const { searchValue } = this.state;
    this.setState({
      pending: true,
      chooseListType: type,
    });
    this.handleSearch({ searchValue, searchType: type });
  }

  searchMember = (value) => {
    const { chooseListType } = this.state;
    const searchValue = value;
    if (!searchValue) {
      this.setState({
        searchValue,
        chooseListType: 0,
      });
      return;
    }

    this.setState({
      pending: true,
      searchValue,
    });

    this.handleSearch({ searchValue, searchType: chooseListType });
  }

  handleClickOutside = (e) => {
    if (this.dropdownMenu && this.dropdownMenu.contains(e.target)) {
      return;
    }
    this.setState({
      searchValue: '',
    });
  }

  handleDeselect = (id) => {
    const chooseList = this.state.chooseList.filter((item) => item.id !== id);
    this.setState({
      chooseList,
    },
    () => {
      this.props.onChange(chooseList);
    });
  }

  readMore = (type) => {
    this.handleChooseTab(type);
    const { onClickMore } = this.props;
    if (typeof onClickMore === 'function') {
      onClickMore(type);
    }
  }

  onLoadPaginateData = async (pageNum) => {
    const { searchValue } = this.state;
    const { currId: resourceId, searchFun } = this.props;
    const data = await searchFun({
      keyword: searchValue,
      searchType: ENUM_TABS.USER,
      resourceId: resourceId || '',
      pageNum,
      itemSize: 10,
    });
    const { source } = this.state;
    const { hasMore, items } = data[0];
    if (data) {
      source.push(...items);
    }
    this.setState({
      source: [...source],
      hasMore,
    });
  }

  handleSearch = debounce((data) => this.reqSearchData(data), 5e2);

  reqSearchData = async ({ searchValue, searchType, pageNum }) => {
    const { currId: resourceId, searchFun } = this.props;
    this.setState({
      pending: true,
    });
    const data = await searchFun({
      keyword: searchValue,
      searchType,
      resourceId: resourceId || '',
      pageNum,
      itemSize: searchType === 0 ? 4 : 10, // 全部tab每项最多展示3条，其他用户/每类群组最多展示10条
    });
    let source = data;
    let hasMore = false;

    if (data.length > 0) {
      if (searchType === ENUM_TABS.ALL) {
        const allList = [
          { label: '用户', type: ENUM_TABS.USER, items: [] },
          { label: '群组', type: ENUM_TABS.GROUP, items: [] },
        ];
        data.map((item) => {
          allList[item.type < 1 ? 0 : 1].items.push(...item.items);
        });
        source = allList;
      } else {
        source = data.reduce((a, b) => a.concat(b.items), []);
        hasMore = !!data.find((item) => item.hasMore);
      }
    }
    this.setState({
      source,
      hasMore,
      pending: false,
      chooseListType: searchType,
    });
  }

  chooseSearchItem = (user) => {
    const { onClickItem } = this.props;
    if (typeof onClickItem === 'function') {
      onClickItem(user);
    }
    let { chooseList } = this.state;
    const isExist = chooseList.some((item) => item.id === user.id);
    if (isExist) {
      message.info(intl.t('已选择'));
    } else {
      chooseList = chooseList.concat(user);
    }
    this.setState(
      {
        searchValue: '',
        chooseListType: 0,
        userLis: [],
        teamList: [],
        chooseList,
      },
      () => {
        this.props.onChange(chooseList);
      },
    );
  }

  detectIsSelected = (id) => {
    return this.state.chooseList.some((item) => item.id === id);
  }

  __renderTabs = (chooseListType) => {
    return (
      <div
        className={cx({
          'list-header': true,
          'user-tab': chooseListType !== 0,
        })}>
        {
          SCROLL_TAB.map((value) => {
            return (
              <div
                className={cx('title-item')}
                key={value.type}
                onClick={() => this.toggleTab(value.type)}>
                <h2
                  className={cx({
                    'title-item-txt': true,
                    'txt-checked': chooseListType === value.type,
                  })}>{value.value()}</h2>
                {chooseListType === value.type && <div className={cx('title-check-flag', 'flag-checked')} />}
              </div>
            );
          })
        }
      </div>
    );
  }

  _renderUserList = (data) => {
    const source = data || this.state.source;
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.onLoadPaginateData}
        initialLoad={false}
        useWindow={false}
        hasMore={this.state.hasMore}
        loader={<Loading loading={true} />}
      >
        {
          source.map((item) => (
            <div
              key={item.id}
              className={cx('person-card-wrap')}
              onClick={() => this.chooseSearchItem(item)}>
              <PersonCard
                avatar={item.avatar || defaultAvatar}
                name={item[intl.t('title')] || '--'}
                department={item.dep}
                mail={item.mail}
                depWidth={300}
                type={item.type}
              />
            </div>
          ))
        }
      </InfiniteScroll>
    );
  }

  _renderTeamList = (data) => {
    return (
      data.map((item) => (
        <div
          key={item.id}
          className={cx('person-card-wrap')}
          onClick={() => this.chooseSearchItem(item)}>
          <PersonCard
            avatar={item.avatar || defaultTeamAvatar}
            name={item[intl.t('title')] || '--'}
            depWidth={300}
            memberCount={item.memberCount || null} // 暂时只有dc群组有人数
            type={item.type}
          />
        </div>
      ))
    );
  }

  get _renderMemberList() {
    const { source, chooseListType } = this.state;
    if (chooseListType === ENUM_TABS.USER) return this._renderUserList();
    if (chooseListType === ENUM_TABS.GROUP) return this._renderTeamList(source);
    return (
      <div>
        {
          source.map((item, index) => (
            item.items.length > 0
              ? <div
                  className={cx('search-result-group')}
                  key={item.type}>
                <h4 className={cx('group-title')}>
                  {
                    item.label && intl.t(item.label)
                  }
                  {
                    item.type === ENUM_TABS.GROUP && <span className={cx('group-title-tip')}>({intl.t('群组中的人会被打散添加进来，后续进群人员数据不做更新')})</span>
                  }
                  {/* {
                    item.type === ENUM_TABS.USER && <span className={cx('group-title-tip')}>({intl.t('外部联系人需为D-Chat外部联系人后可进行添加')})</span>
                  } */}
                </h4>
                <div className={cx('group-content')}>
                  {
                    index === 0
                      ? this._renderUserList(item.items.slice(0, 3))
                      : this._renderTeamList(item.items.slice(0, 3))
                  }
                  {
                    item.items.length > 3 && <MoreMember
                      value={SCROLL_TAB.filter((tab) => tab.type === item.type)[0].value()}
                      onChoose={() => this.readMore(item.type)} />
                  }
                </div>
              </div> : null
          ))
        }
      </div>
    );
  }

  get listView() {
    const { source, searchValue, chooseListType } = this.state;

    if (!searchValue) {
      return <div className={cx('white-height')} />;
    }
    return (
      source && source.length > 0
        ? <div className={cx('result-content')}>
          {chooseListType === 2 && <p className={cx('result-content-tip')}>{intl.t('群组中的人会被打散添加进来，后续进群人员数据不做更新')}</p>}
          {this._renderMemberList}
        </div>
        : <div className={cx('no-search-result')}>{intl.t('搜索无结果')}</div>
    );
  }

  tagRender = ({ value }) => {
    const member = this.state.chooseList.find((item) => item.id === value);
    let text;
    if (member.type === 0) {
      text = `${member.title}（${shortenDep(member.dep)}）${member.pinyinname}`;
    } else {
      text = `${member.title}（from: ${getGroupType(member)} ${shortenDep(member.dep)}）`;
    }

    return (
      <Tag
        className={'member-tag'}
        key={value}
        closable={true}
        onClose={this.handleDeselect.bind(this, value)}
      >
        <span className={'member-tag-text'}>
          {text}
        </span>
      </Tag>
    );
  }

  render() {
    const { chooseListType, pending, searchValue, chooseList } = this.state;
    const { placeholder } = this.props;
    const chooseIdList = chooseList.map((item) => item.id);
    return (
      <div>
        <Select
          mode='multiple'
          filterOption={false}
          style={{ width: '100%' }}
          placeholder={placeholder}
          value={chooseIdList}
          searchValue={searchValue}
          tagRender={this.tagRender}
          onSearch={this.searchMember}
          open={!!searchValue}
          dropdownClassName={cx('member-selector-drop')}
          dropdownRender={() => (
            <div
              ref={(ele) => this.dropdownMenu = ele}
              className={cx('search-result-list')}>
              <div>
                {this.__renderTabs(chooseListType)}
                {
                  pending
                    ? <Loading loading={true} />
                    : this.listView
                }
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

export default onClickOutside(MemberSearch);
