import { intl } from 'di18n-react';
import React from 'react';
import { Input, message } from 'antd';
import { debounce, once } from 'lodash-es';
import { connect } from 'react-redux';
import PrimaryModal from '@/components/common/PrimaryModal';
import { checkInputValue } from '@/utils/cooperutils';
import CTree from './c-tree';
import { searchFolderTree, doCreateFolder } from '@/service/cooper/createFolder';
import getPathInfo from './getPathInfo';
import { N_ROOT, N_PERSONAL, N_TEAMROOT } from '@/constants/cooper';
import './index.less';
import { ModalCloseIcon } from '@/assets/icon/index';
import { spacePerson as iconPersonalSpace, spaceTeam as iconTeamSpace } from '@/assets/icon/fileIcon';

class CreateFolder extends React.Component {
  static defaultProps = {
    defaultPath: '',
    doneCallback: () => { },
    onClose: () => { },
  };

  personalRoot = {
    type: N_PERSONAL,
    id: 0,
    name: intl.t('个人空间'),
    path: intl.t('个人空间'),
    icon: iconPersonalSpace,
    children: [],
    depth: 2,
    hasOpened: false,
    hasChildren: true,
    hasSelected: false,
  };

  teamListRoot = {
    type: N_TEAMROOT,
    id: N_TEAMROOT,
    name: intl.t('团队空间'),
    path: intl.t('团队空间'),
    icon: iconTeamSpace,
    children: [],
    depth: 2,
    hasOpened: false,
    hasChildren: true,
    hasSelected: false,
  };


  state = {
    name: intl.t('新建文件夹'),
    keyword: '',
    cooperRoot: {
      type: N_ROOT,
      id: N_ROOT,
      name: 'Cooper',
      path: '',
      children: [this.personalRoot, this.teamListRoot],
      depth: 1,
    },
    tree: {},
    showTree: false,
    showClear: false,
    confirmLoading: false,
    errorText: '',
    isShowPerson: true,
  }; // autoFocus not work on Modal
  // https://github.com/ant-design/ant-design/issues/8668

  async componentDidMount() {
    const { cooperRoot } = this.state;
    const { IsExternalTenant } = this.props;


    if (IsExternalTenant) {
      this.setState({
        cooperRoot: {
          type: N_ROOT,
          id: N_ROOT,
          name: 'Cooper',
          path: '',
          children: [this.teamListRoot],
          depth: 1,
        },
        tree: {
          type: N_ROOT,
          id: N_ROOT,
          name: 'Cooper',
          path: '',
          children: [this.teamListRoot],
          depth: 1,
        },
      })
    } else {
      this.setState({
        tree: cooperRoot,
      })
    }

    setTimeout(() => {
      this.inputRef.focus();
    }, 0);
    const { parentName, parentId, teamId } = await getPathInfo();
    this.setState({
      keyword: parentName,
      isShowPerson: !IsExternalTenant,
    });
    this.selected = {
      id: parentId,
      teamId,
    };
  }

  inputRef = null; // 新建文件夹的父节点

  selected = null;

  selectText = once((input) => {
    const endPos = input.value.length;
    input.setSelectionRange(0, endPos);
  });

  handleSearch = debounce(async (keyword) => {
    const tree = await searchFolderTree(keyword);

    if (this.state.keyword) {
      this.setState({
        tree,
      });
    }
  }, 300);

  handleChange = (e) => {
    const { cooperRoot } = this.state;
    const { value } = e.target;
    this.setState({
      keyword: value,
    }); // 取最后一个路径

    const keyword = value.split('/').reverse()[0];

    if (keyword) {
      this.handleSearch(keyword);
    } else {
      this.setState({
        tree: cooperRoot,
      });
    }
  };

  handleSelect = (node) => {
    this.selected = node;
    this.setState({
      keyword: node.path,
      showTree: false,
    });
  };

  handleCreate = async () => {
    const { name } = this.state;
    const errorMsg = checkInputValue(name);
    window.__OmegaEvent('ep_create_folder_ck');
    if (errorMsg.length > 0) {
      message.error(errorMsg);
      return;
    }
    this.setState({
      confirmLoading: true,
    });
    let { id, teamId = 0, type } = this.selected;
    if (type === 'TEAM') {
      teamId = id;
      id = 0;
    }
    doCreateFolder(name.trim(), id, teamId).then(() => {
      message.success(intl.t('创建文件夹成功'));
      this.props.onClose();
      this.props.doneCallback();
    }).catch((err) => {
      if (err.errorCode === 1082) {
        message.error(intl.t('您没有权限！请联系空间管理员添加上传权限。'));
      } else {
        message.error(err.message);
      }
    });

    this.setState({
      confirmLoading: false,
    });
  };

  clear = () => {
    const { cooperRoot } = this.state;
    this.personalRoot.children = [];
    this.teamListRoot.children = [];
    this.setState({
      keyword: '',
      tree: cooperRoot,
    });
  };

  inputHandleChange = (e) => {
    const { value } = e.target;
    let errorText = checkInputValue(value);
    this.setState({
      name: value,
      errorText,
    });
  }

  render() {
    const { onClose } = this.props;
    const { name, keyword, tree, showTree, showClear, confirmLoading, errorText } = this.state;

    return (
      <PrimaryModal
        title={intl.t('新建文件夹')}
        onOk={this.handleCreate}
        confirmLoading={confirmLoading}
        onCancel={onClose}
      >
        <div className='create-folder'>
          <div className='name'>
            <span className='label'>{intl.t('名称')}</span>
            <Input
              autoFocus
              placeholder={intl.t('请输入文件夹名称')}
              value={name}
              ref={(node) => (this.inputRef = node)}
              onChange={(e) => this.inputHandleChange(e)}
              onFocus={(e) => this.selectText(e.target)}
            />
            {errorText && <p className='create-folder-error'>{errorText}</p>}
          </div>
          <div className='path'>
            <span className='label'>{intl.t('目录位置')}</span>
            <span
              onMouseEnter={() => this.setState({
                showClear: !!keyword,
              })
              }
              onMouseLeave={() => this.setState({
                showClear: false,
              })
              }
            >
              <Input
                data-onclickoutside='ignore'
                value={keyword}
                onChange={this.handleChange}
                onClick={() => this.setState({
                  showTree: true,
                })
                }
              />
              <img
                data-onclickoutside='ignore'
                src={showClear ? ModalCloseIcon : ModalCloseIcon}
                alt={showClear ? 'cancel' : 'down'}
                onClick={showClear ? this.clear : () => { }}
              />
            </span>
            <div
              style={{
                display: showTree ? 'block' : 'none',
              }}
            >
              <CTree
                tree={tree}
                onSelect={this.handleSelect}
                onHide={() => this.setState({
                  showTree: false,
                })
                }
              />
            </div>
          </div>
        </div>
      </PrimaryModal>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    IsExternalTenant: state.GlobalData.IsExternalTenant,
  };
};

export default connect(mapStateToProps)(CreateFolder)


