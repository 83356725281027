import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AdvanceSearch from '@/components/AdvanceSearch';
import GlobalSearch from './GlobalSearch';
import { scopeMap } from '@/constants/search';
import { getLocale, intl } from 'di18n-react';
import { useLocation, useParams } from 'react-router-dom';

const SearchContent = () => {
  const [searchKey, setSearchKey] = useState();
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [searchScopeId, setSearchScopeId] = useState(undefined);
  const [placeholder, setPlaceHolder] = useState('')
  const [searchScopeName, setSearchScopeName] = useState();
  const [inputDisplayName, setInputDisplayName] = useState();

  // TODO:替换currSpaceId方式
  // const {
  //   currSpaceId,
  // } = useSelector((state) => state.TeamData);

  // todo: 控制失焦状态
  const { teamId: currSpaceId } = useParams();
  const { personalSpaceId, personalSpaceName } = useSelector((state) => state.TeamData);

  const [isReset, setIsReset] = useState(0); // 关闭标签页会失焦，再次输入会重新加入标签。无解，暂不使用
  const openSearchModal = (key) => {
    setSearchKey(key);
    setSearchModalVisible(true);
  };

  const { pathname } = useLocation();

  const isInTeamRoute = pathname.includes('/team-file/');
  const isInPersonRoute = pathname.includes('/disk') || pathname.includes('/files/');
  const isInStar = pathname.includes('/favorite');
  const inTeamBoard = pathname.includes('/team-folder');
  // const inKnowledge = pathname.includes('/knowledge/');

  const { teamDetail, currentSpaceType } = useSelector((state) => state.TeamData);

  const clearSearchScope = () => {
    setSearchScopeId('');
    setSearchScopeName('');
    setInputDisplayName('')
  }


  useEffect(() => {
    let scopeId = null;
    let scopeName = null;
    let displayName = null;
    let scopePlaceholder = null;

    if (isInTeamRoute) {
      scopeId = currSpaceId;
      scopeName = teamDetail.name;
      displayName = '当前空间';
      scopePlaceholder = intl.t('搜索文档标题、正文、知识库名');
    } else if (isInPersonRoute) {
      scopeId = scopeMap.PERSONAL;
      scopeName = getLocale() === 'en-US' ? personalSpaceName?.nameEn : personalSpaceName?.nameZh;
      displayName = '当前空间';
      scopePlaceholder = intl.t('搜索文档标题、正文1');
    } else if (isInStar) {
      scopeId = scopeMap.STAR;
      scopeName = '收藏';
      displayName = '收藏';
      scopePlaceholder = intl.t('搜索文档标题、正文1');
    } else if (inTeamBoard) {
      scopeId = scopeMap.TEAMS;
      scopeName = '全部团队空间';
      displayName = '全部团队空间';
      scopePlaceholder = intl.t('搜索文档标题、正文、空间/知识库名');
    } else {
      scopeId = '';
      scopeName = '';
      displayName = '';
      scopePlaceholder = intl.t('搜索文档标题、正文、空间/知识库名');
    }

    setSearchScopeId(scopeId);
    setSearchScopeName(scopeName);
    setInputDisplayName(displayName);
    setPlaceHolder(scopePlaceholder);
  }, [pathname, teamDetail, personalSpaceName, getLocale()]);

  const onFocusInput = () => {
    setIsReset(isReset + 1);
  }

  return <>
    <GlobalSearch
      openSearchModal={openSearchModal}
      clearSearchScope={clearSearchScope}
      searchScopeId={searchScopeId}
      placeholder={placeholder}
      displayName={inputDisplayName}
      onFocus={onFocusInput}
    />
    <AdvanceSearch
      visible={searchModalVisible}
      searchKey={searchKey}
      placeholder={placeholder}
      closeSearchModal={() => {
        setSearchModalVisible(false);
        setSearchKey('')
      }}
      searchScopeId={searchScopeId}
      searchScopeName={searchScopeName}
    />
  </>;
};

export default SearchContent;
