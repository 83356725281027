import { getLocale, intl } from 'di18n-react';
import styles from './style.module.less';
import classBind from 'classnames/bind';
import cls from 'classnames';
import SearchItem from '@/components/SearchItem';
import SpinRender from '@/components/SpinRender';
import NoMore from '@/components/NoMore';
import { getRecentDataForSearch } from '@/service/knowledge/search';
import ErrorTips from '@/components/ErrorTips';
import InfiniteScroll from 'react-infinite-scroller';
import useLoadMore from '@/hooks/useLoadMore';
import { scopeMap } from '@/constants/search';
import { useMemo, useEffect } from 'react';

const cx = classBind.bind(styles);

// 和首页最近访问字段一致
const HistoryResultList = ({ afterClickItem, api, emptyRender, searchScopeId, type }) => {
  // 最近访问暂时不进行范围筛选
  // const params = useMemo(() => {
  //   let defaultParams = {
  //     type,
  //   }
  //   if (!searchScopeId || searchScopeId.length === 0) {
  //     return defaultParams;
  //   }
  //   if (searchScopeId === scopeMap.STAR) {
  //     return {
  //       ...defaultParams,
  //       moduleType: searchScopeId,
  //     }
  //   }
  //   return {
  //     ...defaultParams,
  //     locationId: searchScopeId,
  //   }
  // }, [type, searchScopeId]);

  const {
    loadMore,
    loading,
    list,
    hasMore,
  } = useLoadMore(
    (args) => getRecentDataForSearch({ type, isContainPerson: 1, ...args }),
    { type, isContainPerson: 1 },
  );

  useEffect(() => {
    loadMore({ type, isContainPerson: 1 }, true);
  }, [type])


  return (
    <>
      <p className={cls(`${cx('search-history-title')} history-title`)}>
        {intl.t('最近浏览')}
      </p>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={() => loadMore({ type, isContainPerson: 1 })}
        hasMore={!loading && hasMore}
        useWindow={false}
      >
        {list.length > 0
          && list.map((item, index) => (
            <SearchItem
              key={index}
              data={{
                ...item,
                visitTime: item?.visitTime,
                resourceOwner: getLocale() === 'zh-CN' ? item?.userInfo?.user_cn : (item?.userInfo?.userEn || item?.userInfo?.user_en),
                iconImage: item?.tinyImage,
                dkShareType: item?.shareType,
                pageId: item?.resourceId,
                resourceType: item?.resourceTypeStr,
                resourceId: item.resourceId,
                resourceName: item.resourceName,
                shareId: item?.shareId,
                shareLink: item?.shareLink,
                markedQuickVisit: item?.markQuickVisit,
                teamId: item?.teamId,
                spaceId: item?.spaceId,
                parentId: item?.parentId,
                positionName: item?.filePath,
                scopeName: item?.fileDir,
                knowledgeId: item?.spaceId,
                isShareWithMe: item?.isShareWithMe,
              }}
              position={index}
              callback={() => {
                afterClickItem();
                window.__OmegaEvent('ep_dkpc_dk_advancedsearch_recentview_ck');
              }}
              api={api}
              wrapClass={cx('item-wrap')}
            />
          ))}
        <SpinRender loading={loading} />
        {!loading
          && !list.length
          && (emptyRender ? (
            emptyRender()
          ) : (
            <ErrorTips
              title={intl.t('没有最近浏览记录')}
              img={
                'http://img-ys011.didistatic.com/static/cooper_cn/noSearchResult.png'
              }
            />
          ))}

        {!loading && !hasMore && list.length !== 0 && (
          <NoMore />
        )}
      </InfiniteScroll>
    </>
  );
};

export default HistoryResultList;
