import { intl, getLocale } from 'di18n-react';
import {
  useState, useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { browserHistory } from 'react-router';
import CreateTeam from '@/components/CooperOperation/CreateTeam';
import QuotaDetail from '@/components/QuotaLib/quota-detail';
import CooperApi from '@/utils/request/api/CooperApi';
import { del, post } from '@/utils/request/cooper';
import { Tooltip, message } from 'antd';
import { useNavigate } from 'react-router-dom';
// import CooperSubHeadComponent from '../cooper-sub-head';
// import TipsLoading from '../../common/tips-for-loading';
// import { getTeamPinList } from '../v3/cooper-aside/menu/action';
import SecondLevelTab from '@/components/common/SecondLevelTab';
import { getTeamListInitMine as getTeamListInitMineFn, formatTeamsQuota } from '@/service/cooper/teamSpace';

import classBind from 'classnames/bind';
import TeamSkeleton from '../SkeletonPage/common/TeamSkeleton';
import Tag from '@/components/Tag';
import styles from './style.module.less';
import { isDiDiTenant } from '@/utils/entryEnhance'
// import GetHtml from '@/utils/DOMPurify';

const cx = classBind.bind(styles);

// eslint-disable-next-line import/no-mutable-exports
export let initTeamSpaceList = null

// 团队空间首页-----团队列表页

const _MyTeamTab = () => [
  {
    key: '1',
    title: intl.t('我创建的'),
  },
  {
    key: '2',
    title: intl.t('转交给我的'),
  },
  {
    key: '0',
    title: intl.t('全部'),
  },
];

const CooperTeamList = () => {
  const [loading, setLoading] = useState(true);
  const [totalOwner, setTotalOwner] = useState(null);

  const navigate = useNavigate();

  const { needTeamSpaceSkeleton, userViewData } = useSelector((s) => s.GlobalData);
  const { recent, joined, owned, out, activeOwnerType } = useSelector((s) => s.CooperTeamList);
  const { TeamChooseType } = userViewData ?? {};


  const dispatch = useDispatch();

  const {
    getTeamListInitRecent,
    getTeamListInitMine,
    getTeamListInitNotMine,
    getTeamListInOut,
    removeTeamListInOut,
    setTeamList,
    setOwnerList,
    setOutList,
    setJoinedList,
    setRecentList,
    setActiveOwnerType,
  } = dispatch.CooperTeamList;
  const { getAsideTeamList, setNeedTeamSpaceSkeleton, setUserViewDataRq } = dispatch.GlobalData;

  useEffect(() => {
    window.document.title = `${intl.t('团队空间')}-Cooper`;
    initTeams(); // 除我拥有的之外的列表
    updateOwnerType(activeOwnerType);
    initTeamSpaceList = (typeCurr) => {
      initTeams();
      updateOwnerType(typeCurr);
    } // 外部组件操作后需要更新列表时调用
  }, []);


  useEffect(() => {
    if (TeamChooseType && TeamChooseType !== activeOwnerType) {
      setActiveOwnerType(TeamChooseType);
      updateOwnerType(TeamChooseType);
    }
  }, [userViewData?.TeamChooseType]);


  const updateOwnerType = async (type) => {
    const ownerType = type ?? activeOwnerType
    const sortField = ownerType === '0' ? '1' : '0'; // 0 创建时间 1访问时间
    const resOwn = await getTeamListInitMine({ ownerType, sortField });
    if (ownerType === '0') setTotalOwner(resOwn.length)

    setLoading(false);
    setNeedTeamSpaceSkeleton(false);

    let formatOwned = await formatTeamsQuota(resOwn)
    setOwnerList({
      owned: formatOwned,
    })
  }


  const initTeams = async () => {
    const resRecent = await getTeamListInitRecent();
    const formateRecent = await formatTeamsQuota(resRecent ?? []);
    setLoading(false);
    setNeedTeamSpaceSkeleton(false);
    setRecentList({
      recent: formateRecent,
    })


    if (activeOwnerType !== '0') {
      const res = await getTeamListInitMineFn({ ownerType: '0', sortField: '1' }); // 全部
      setTotalOwner(res.length)
    }


    const resJoined = await getTeamListInitNotMine();
    const formateJoin = await formatTeamsQuota(resJoined ?? []);
    setJoinedList({
      joined: formateJoin,
    })

    const resOut = await getTeamListInOut();
    const formateOut = await formatTeamsQuota(resOut ?? []);
    setOutList({
      out: formateOut,
    })
  };


  const goToTeam = (t, type) => {
    if (type === 'recent') {
      window.__OmegaEvent('ep_team_home_visitteam_ck', '', {
        platform: 'new',
        type: 'recent',
      });
    }
    if (type === 'owned') {
      window.__OmegaEvent('ep_team_home_visitteam_ck', '', {
        platform: 'new',
        type: 'owned',
      });
    }
    if (type === 'joined') {
      window.__OmegaEvent('ep_team_home_visitteam_ck', '', {
        platform: 'new',
        type: 'joined',
      });
    }
    navigate(`/team-file/${t.id}/home`);
  };


  const handleDing = (item) => {
    if (item.pinned) {
      // sendEvent('HOME_TEAM_OPERATION_CANCEL_TOP');
      del(CooperApi.PIN_SPACE.replace(':teamId', item.id)).then(() => {
        message.success(intl.t('已取消置顶'));
        initTeams();
        updateOwnerType();
        getAsideTeamList();
      });
    } else {
      // sendEvent('HOME_TEAM_OPERATION_TOP');
      post(CooperApi.PIN_SPACE.replace(':teamId', item.id)).then(() => {
        initTeams();
        updateOwnerType();
        message.success(intl.t('置顶成功'));
        getAsideTeamList();
      });
    }
  };

  const renderEmpty = () => {
    return (
      <div className={cx('ctl-empty-tip')}>
        <img
          className={cx('ctl-et-img')}
          src={require('./icon/empty.png')} />
        <p className={cx('ctl-et-text')}>
          {!isDiDiTenant() ? intl.t('暂无团队空间') : intl.t('暂无团队，快去{creation}吧', {
            creation: <span
              className={cx('ctl-et-link')}
              onClick={createTeam}>
              {intl.t('新建团队')}
            </span>,
          })}
        </p>
      </div>
    );
  };

  const renderEmptyOwner = () => {
    return (
      <div className={cx('ctl-empty-tip', 'ctl-empty-tip-owner')}>
        <img
          className={cx('ctl-et-img')}
          src={require('./icon/empty.png')} />
        <p className={cx('ctl-et-text')}>
          {intl.t('暂无数据')}
        </p>
      </div>
    );
  };


  const createTeam = () => {
    window.__OmegaEvent('ep_team_create_ck', '', {
      source: 'leftnavigation',
      platform: 'new',
    });
    CreateTeam(() => {
      initTeams();
      updateOwnerType();
    });
  };

  const renderTeamTitle = (type, t) => {
    if (type === 'out') {
      return `${intl.t('外部空间')}-${t.spaceName}`
    }
    return t.name
  };

  const onClickOwnerTab = (currKey) => {
    let data = { ...userViewData };
    data.TeamChooseType = currKey;
    if (JSON.stringify(userViewData) === '{}') return;
    setUserViewDataRq(data);

    updateOwnerType(currKey)
    setActiveOwnerType(currKey);
  }


  const renderTeamListBox = ({ teams, title, type }) => {
    return (
      <div className={cx('ctl-box')}>
        <div className={cx('ctl-box-title')}>{title}</div>
        {
          type === 'owned' && <SecondLevelTab
            tabList={_MyTeamTab()}
            onClick={onClickOwnerTab}
            currKey={activeOwnerType}
          />
        }
        {
          teams && teams?.length > 0
            ? <div className={cx('ctl-box-list')}>
              {teams.map((t) => {
                return (
                  <div
                    className={cx({ 'ctl-box-item': true, 'ctl-box-item-out': type === 'out' })}
                    key={t.id}
                    onClick={() => (type !== 'out' || (type === 'out' && t.status === 'Agree') ? goToTeam(t, type) : {})}
                    >
                    <div className={cx('f1')}>
                      {/* <div className={cx('ctl-bi-logo-default')} /> */}
                      <img
                        className={cx('ctl-bi-logo-default')}
                        src='https://img-ys011.didistatic.com/static/cooper_cn/spaceTeam.png' />
                      <div
                        className={cx('ctl-bi-name')}
                        style={{
                          WebkitBoxOrient: 'vertical',
                        }}
                        title={renderTeamTitle(type, t)}
                        >
                        {/* {(t.relationTypeTags || []).indexOf('SPACE_DEPART') > -1 && <img
                              className={cx('team-dk-departmentTag')}
                              src='http://img-ys011.didistatic.com/static/cooper_cn/do1_kfJrUTPivjaHXd1QLgmh'
                            />} */}
                        {
                            (t.relationTypeTags || []).indexOf('SPACE_DEPART') > -1 && (
                              <Tag
                                type='yellow-primary'
                                text={intl.t('部门')} />
                            )
                          }
                        {
                            (t.relationTypeTags || []).indexOf('IN_OUT') > -1 && (
                              <Tag
                                type='out-yellow'
                                text={intl.t('外部')} />
                            )
                          }
                        <span className={cx('f1-text')}>
                          {renderTeamTitle(type, t)}
                        </span>
                      </div>
                    </div>
                    {
                        type !== 'out' && (
                          <div className={cx('ctl-bi-info')}>
                            {t.check ? (
                              <div className={cx('ctl-bi-info-private')}>{intl.t('私密')}</div>
                            ) : (
                              <div className={cx('ctl-bi-info-public')}>{intl.t('公开')}</div>
                            )}
                            <div className={cx('ctl-bi-info-member')}>
                              {intl.t('{count}人', { count: t.member_count })}
                            </div>
                            {t.hasQuota && t.space_quota && (
                              <QuotaDetail
                                config={{
                                  teamId: t.id,
                                  useUp: t.used_quota,
                                  total: t.space_quota,
                                  applied: Boolean(t.quota_bpm_url),
                                  bpmUrl: t.quota_bpm_url,
                                  isOwner: title === intl.t('我创建的') || t.owner,
                                }}
                                success={() => {
                                  initTeams();
                                  updateOwnerType();
                                }}
                              />
                            )}
                            {t.originalOwner && <Tooltip
                              title={intl.t('原所有者:{slot}', {
                                slot: getLocale() === 'zh-CN' ? t.originalOwner : t.originalOwnerEn,
                              })}>
                              <div className={cx('clt-bi-info-owner')}>
                                {getLocale() === 'zh-CN' ? t.originalOwner : t.originalOwnerEn}
                              </div>
                            </Tooltip>}
                          </div>
                        )
                      }
                    {
                        type === 'out' && (
                          <div className={cx('ctl-bi-info-out')}>
                            <div className={cx('ctl-bi-info-out-status-box')}>
                              <span
                                className={cx({
                                  'ctl-bi-info-out-status': true,
                                  'ctl-bi-info-out-status-reject': t.status === 'Reject',
                                  'ctl-bi-info-out-status-applied': t.status === 'Applied',
                                })}>
                                <i className={cx({ 'dk-iconfont': true, 'dk-icon-rongqi1': t.status === 'Reject', 'dk-icon-zuijin1': t.status === 'Applied', 'status-cion': true })} />
                                <span>
                                  {t.status === 'Reject' ? intl.t('申请被驳回') : intl.t('申请中')}
                                </span>
                              </span>
                            </div>
                            <div className={cx('ctl-bi-info-out-action-box')}>
                              {
                                t.status === 'Reject' && <span
                                  onClick={() => {
                                    removeTeamListInOut(t.id).then(() => {
                                      setTeamList({
                                        recent: [...recent],
                                        joined: [...joined],
                                        owned: [...owned],
                                        out: out.filter((item) => item.id !== t.id),
                                      })
                                    })
                                  }}>{intl.t('移除')}</span>
                              }
                              <span
                                onClick={() => {
                                  window.open(t.bpmUrl)
                                }}>{intl.t('查看')}</span>
                            </div>
                          </div>
                        )
                      }
                    {type !== 'out' && <Tooltip
                      title={t.pinned ? intl.t('取消置顶') : intl.t('置顶')}
                      arrowPointAtCenter
                      placement='top'
                      >
                      <div
                        className={cx({
                          'ctl-bi-ding': true,
                          'ctl-bi-dinged': t.pinned,
                        })}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDing(t);
                        }}
                        >
                        <i className={cx('dk-iconfont', t.pinned ? 'dk-icon-quxiaozhiding' : 'dk-icon-zhiding4px')} />
                      </div>
                    </Tooltip>}
                  </div>
                );
              })}
            </div>
            : renderEmptyOwner()
        }
      </div>
    );
  };

  return (
    <>
      {
        needTeamSpaceSkeleton ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: TeamSkeleton }} />
          </>
        ) : (
          <div className={cx('cooper-team-list', 'cooper-team-list-os-flag')}>
            {recent?.length === 0 && totalOwner === 0 && joined?.length === 0
              && renderEmpty()
            }
            <div className={cx('ctl-content', 'ctl-content-os-flag')}>
              {
                out?.length > 0 && renderTeamListBox({
                  teams: out,
                  title: `${intl.t('审批状态')}`,
                  type: 'out',
                })
              }
              {
                recent?.length > 0 && renderTeamListBox({
                  teams: recent,
                  title: intl.t('最近访问'),
                  type: 'recent',
                })
              }
              {
                isDiDiTenant() && totalOwner > 0 && renderTeamListBox({
                  teams: owned,
                  title: `${intl.t('我拥有的')}(${totalOwner ?? 0})`,
                  type: 'owned',
                })
              }
              {
                joined?.length > 0 && renderTeamListBox({
                  teams: joined,
                  title: `${intl.t('我参与的')}(${joined.length})`,
                  type: 'joined',
                })
              }
            </div>
          </div>
        )
      }
    </>
  );
}


export default CooperTeamList;
