import React from 'react';
import { calcTextLength } from './constant';

// 1-部门信息只展示一行，且不超过所先定的展示区域
// 2-如部门信息在限定区域内可展示完全，则需支持全部信息的展示
// 3-如部门信息未展示完全的，支持鼠标hover上后出现全部部门信息展示的小浮层
// 4-如在限定区域内部门信息展示不全，但可支持1/3级部门展示，则2级部门做最大字符数展示，即展示为：1级部门字段/2级部门字段.../3级部门字段
// 5-如在限定区域内部门信息展示不全，只支持3级部门的全部展示，则1级部门做最多字符数展示，2级部门做缺省，即展示为：1级部门字段.../.../3级部门字段
// 6-如在限定区域内已不支持较长的3级部门的完整展示，则尽量展示3级部门信息，1级和2级部门做缺省，即展示为：3级部门字段...
class PrettyDep extends React.Component {
  formatText = () => {
    const { bracket, maxWidth, text, fontSize, fontWeight, fontFamily } = this.props;
    let spts = text.split('/');

    // 如果数组长度超过3个，将中间的合并为1个
    if (spts.length > 3) {
      const first = spts.shift();
      const last = spts.pop();
      spts = [first, spts.join('/'), last];
    }

    // 格式不满足3级部门要求，不予处理
    if (spts.length !== 3) {
      return text;
    }

    function calcLen(textCurr) {
      return calcTextLength(textCurr, fontSize, fontWeight, fontFamily);
    }

    const sepLen = calcLen('/');
    const ellLen = calcLen('...');
    const len0 = calcLen(spts[0]);
    const len1 = calcLen(spts[1]);
    const len2 = calcLen(spts[2]);
    const width = bracket ? maxWidth - calcLen('（）') : maxWidth;

    // 情况2
    if (len0 + len1 + len2 + 2 * sepLen <= width) {
      return text;
    }

    // 情况4
    if (len0 + len2 + 2 * sepLen + ellLen < width) {
      const remain = width - (len0 + len2 + 2 * sepLen + ellLen);

      for (let i = 1; i < spts[1].length; i++) {
        const len = calcLen(spts[1].slice(0, i));
        if (len > remain) {
          return `${spts[0]}/${spts[1].slice(0, i - 1)}.../${spts[2]}`;
        }
      }

      return `${spts[0]}/${spts[1]}.../${spts[2]}`;
    }

    // 情况5
    if (len2 + 2 * sepLen + 2 * ellLen < width) {
      const remain = width - (len2 + 2 * sepLen + 2 * ellLen);

      for (let i = 1; i < spts[0].length; i++) {
        const len = calcLen(spts[0].slice(0, i));
        if (len > remain) {
          return `${spts[0].slice(0, i - 1)}.../.../${spts[2]}`;
        }
      }

      return `${spts[0]}.../.../${spts[2]}`;
    }

    // 情况6
    return spts[2];
  }

  render() {
    const { bracket, text } = this.props;
    const formatText = this.formatText();

    return (
      <span
        title={ text }
        className='depart-text'>
        { bracket && formatText ? `（${formatText}）` : formatText }
      </span>
    );
  }
}

PrettyDep.defaultProps = {
  bracket: true,
  maxWidth: 240,
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: 'inherit',
};

export default PrettyDep;
