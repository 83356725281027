import { intl } from 'di18n-react';

import { useState, useEffect, useMemo } from 'react';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const SearchOnScope = ({ clearScope, displayName }) => {
  return (
    <div className={cx('search-before')}>
      <i
        className={`${cx('dk-icon-sousuo', 'dk-iconfont')}`}
        style={{ color: '#bbb' }}
      />

      {
        !!displayName && (
          <p className={cx('search-tag')}>
            {intl.t(displayName)}
            <span
              style={{ cursor: 'pointer', height: 20 }}
              onClick={() => clearScope()}
          >
              <i
                className={`${cx('dk-icon-guanbi', 'dk-iconfont')}`}
                style={{ fontSize: 20 }}
            />
            </span>
          </p>
        )
      }
    </div>
  );
};

export default SearchOnScope;
