/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-09-04 11:14:13
 * @LastEditTime: 2023-09-04 11:14:46
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/common/PersonCard/index.js
 *
 */
import React from 'react';
import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import PrettyDep from './PrettyDep';
import { TEAM_TYPE } from './constant';
import styles from './index.modules.less';

let cx = classBind.bind(styles);
class PersonCard extends React.Component {
  getTeamType = (type) => {
    const target = TEAM_TYPE.find((item) => item.type === type);
    if (target) {
      return intl.t('来自:') + target.value();
    }
    return '';
  }

  render() {
    const {
      bracket,
      avatar,
      name,
      type,
      department = null,
      mail,
      memberCount = null,
      depWidth,
      fontSize,
      fontWeight,
      fontFamily,
      exist,
    } = this.props;

    // type === 0 : 用户

    const smallText = type === 0 ? mail : this.getTeamType(type);

    return (
      <div className={cx('person-card')}>
        <img src={ avatar }/>
        <div className={cx('info')}>
          <div className={cx('info-name-msg')}>
            <span
              className={cx({
                name: true,
                'team-name': type !== 0,
              })}
              title={name}
            >
              {name}
            </span>
            { type === 0 && !!department
              && <PrettyDep
                bracket={ bracket }
                maxWidth={ depWidth }
                text={ department }
                fontSize={ fontSize }
                fontWeight={ fontWeight }
                fontFamily={ fontFamily }
              />
            }
            {
              type !== 0 && memberCount && <span>({memberCount}{intl.t('人')})</span>
            }
            {
              exist && (<span className={cx('exist-tag')}>{intl.t('已添加')}</span>)
            }
          </div>
          <p className={cx('info-text-msg')}>{ smallText }</p>
        </div>
      </div>
    );
  }
}

PersonCard.defaultProps = {
  bracket: true,
  depWidth: 240,
  fontSize: '14px',
  fontWeight: 400,
  fontFamily: 'inherit',
};

export default PersonCard;
