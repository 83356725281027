import { intl } from 'di18n-react';

export const TEAM_TYPE = [
  {
    type: 1,
    value: () => intl.t('OE'),
  }, {
    type: 2,
    value: () => intl.t('Cooper团队'),
  }, {
    type: 3,
    value: () => intl.t('邮件组'),
  }, {
    type: 4,
    value: () => intl.t('D-Chat'),
  },
];


export function calcTextLength(
  text,
  fontSize,
  fontWeight = 400,
  fontFamily = 'inherit',
) {
  const span = document.createElement('span');
  span.innerText = text;
  Object.assign(span.style, {
    fontFamily,
    fontSize,
    fontWeight,
    display: 'inline-block',
    height: 0,
    overflow: 'hidden',
  });
  document.body.appendChild(span);
  const length = span.offsetWidth;
  document.body.removeChild(span);
  return length;
}
