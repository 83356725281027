/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-09-11 20:27:21
 * @LastEditTime: 2023-09-14 14:54:55
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/LayoutCooper/Header/NewUpload/index.js
 *
 */
import { intl } from 'di18n-react';
import { Fragment } from 'react';
// import { connect } from 'react-redux';
import { Button, message, Popover } from 'antd';
import { isDesktopDC } from '@/utils';
import TextOnlyModal from '@/components/TextOnlyModal';
import { uploadFile, uploadFolder } from '@/assets/icon/fileIcon';
import { useSelector } from 'react-redux';
import './index.less';

function NewUpload({ isOuter }) {
  const { uploadCase } = useSelector((state) => state.UploadList);
  const { IsExternalTenant } = useSelector((state) => state.GlobalData);

  // const { uploadCase } = this.props;
  const canUpload = (isDir) => {
    if (isDesktopDC && isDir) {
      message.warning(intl.t('功能暂未开放，您可以尝试拖拽上传'));
      return false;
    }

    // if (!navigator.onLine) {
    //   TextOnlyModal.confirm({
    //     type: 'warn',
    //     title: intl.t('当前网络异常，请检查网络后重试'),
    //     cancelText: intl.t('知道了'),
    //   });
    //   return false;
    // }

    return true;
  };

  const _uploadFile = () => {
    window.__OmegaEvent('ep_upload_files_ck', '', {
      platform: 'new',
    });

    if (canUpload(false)) {
      uploadCase.triggerUFiles(isOuter);
    }
  };

  function _uploadFolder() {
    window.__OmegaEvent('ep_upload_folder_ck', '', {
      platform: 'new',
    });

    if (canUpload(true)) {
      uploadCase.triggerUFolders(isOuter);
    }
  }

  const uploadContent = () => {
    return (
      <ul className='menu menu-upload'>
        <li
          onClick={_uploadFile}
          className='menu-upload-li'
        >
          <img
            src={uploadFile}
            className='upload-img'
          />
          <p className='upload-text'>{intl.t('上传文件', 'onestep')}</p>
        </li>
        <li
          onClick={_uploadFolder}
          className='menu-upload-li'
        >
          <img
            src={uploadFolder}
            className='upload-img'
          />
          <p className='upload-text'>{intl.t('上传文件夹')}</p>
        </li>
      </ul>
    );
  };

  return (
    <Fragment>
      <Popover
        placement='bottomRight'
        content={uploadContent()}
        trigger='hover'
      >
        <Button
          type='secondary'
          icon={<i className='dk-iconfont dk-icon-shangchuan2' />}
          onClick={() => { window.__OmegaEvent('ep_upload_ck'); }}
        >
          {intl.t('上传')}
        </Button>
      </Popover>
    </Fragment>
  );
}

// const mapStateToProps = ({ UploadList }) => {
//   const { uploadCase } = UploadList;

//   return {
//     uploadCase,
//   };
// };

// export default connect(mapStateToProps)(NewUpload);
export default NewUpload;
