
import { highlight } from '@/utils';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);

function TagItem({ optionData }) {
  return (
    <div className={cx('user-item')}>
      <p
        title={optionData?.name?.replace(/§§/g, '')}
        className={cx('text')}>
        {highlight(optionData?.name || '')}
      </p>
    </div>
  )
}

export default TagItem;
