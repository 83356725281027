import React from 'react';
import { intl } from 'di18n-react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import TemplateList from './template-list';
import TemplatePreview from './template-preview/index';
import './index.less';

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  goBackTemplateList = () => {
    this.props.goBackTemplateList();
  }

  unmountedModal = () => {
    this.props.clearState();
    this.props.handleCancel();
  }

  render() {
    const { showTemplate } = this.props;
    const {
      titleText,
      pageStep,
      currentTemplateIndex,
      currentTemplateList,
    } = this.props;
    let title = pageStep === 0
      ? (
        <div className='title-container'>
          <p>{intl.t('选择模板')}</p>
        </div>
      )
      : (
        <div className='title-container title-container-style'>
          <i
            onClick={this.goBackTemplateList}
            className='dk-iconfont dk-icon-fanhuiyemian back' />
          <p>{intl.t(titleText)}</p>
        </div>
      );
    return (
      <Modal
        className='template-modal-wrap'
        visible={showTemplate}
        title={title}
        onCancel={this.unmountedModal}
        footer={null}
        width={700}
        style={{
          borderRadius: '8px',
        }}
        bodyStyle={{
          borderRadius: '8px',
        }}
      >

        <TemplateList
          cls={`${pageStep === 1 ? 'hidden' : ''} `}
        />
        {
          pageStep === 1 && <TemplatePreview
            templateList={currentTemplateList}
            templateIndex={currentTemplateIndex}
          />
        }
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const ca = state.Coopertemplate;
  return {
    fileType: ca.fileType,
    pageStep: ca.pageStep,
    titleText: ca.titleText,
    currentTemplateList: ca.currentTemplateList,
    currentTemplateIndex: ca.currentTemplateIndex,
  };
};

const mapDispatchToProps = (Template) => {
  const {
    goBackTemplateList,
    clearState,
  } = Template.Coopertemplate
  return {
    goBackTemplateList: () => goBackTemplateList(),
    clearState: () => clearState(),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Template);
