import { intl } from 'di18n-react';
/* eslint-disable */
import { Tooltip, Select } from 'antd';
import { debounce } from 'lodash-es';
import EmptyRender from '@/components/SpinRender';
import classBind from 'classnames/bind';
import styles from './style.module.less';
import { useMemo, useState, useRef, useEffect } from 'react';

const { Option } = Select;

const cx = classBind.bind(styles);

export default function SelectInput(props) {
  const {
    fetchOptions,
    placeholder,
    onChange,
    currentId,
    height,
    inputRef,
    renderNodeItem,
    defaultValue,
    reset,
    ...other
  } = props;
  const [inputValue, setInputValue] = useState(defaultValue);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const loadOptions = (keyWord) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);
    setFetching(true);
    fetchOptions(keyWord).then((newOptions) => {
      if (!newOptions) return;
      if (fetchId !== fetchRef.current) {
        return;
      }
      // 最近浏览中找到当前元素放置列表第一个
      if (!keyWord) {
        let currentIndex = newOptions.findIndex((v) => v.keyWord === currentId);
        if (currentIndex > -1) {
          // 列表中有元素
          let current = newOptions.splice(currentIndex, 1)[0];
          newOptions.unshift(current);
        }
      }
      setOptions(newOptions);
      setFetching(false);
    });
  };

  const debounceFetcher = useMemo(() => {
    return debounce(loadOptions, 800);
  }, [fetchOptions, 800]);

  const onFocus = () => {
    loadOptions();
  };

  useEffect(() => {
    if (reset) {
      setInputValue(undefined);
    }
  }, [reset]);

  return (
    <div className={cx('select-input-wrap')}>
      <Select
        value={inputValue}
        allowClear
        ref={inputRef}
        mode={props.mode}
        showArrow={false}
        placeholder={placeholder}
        showSearch
        dropdownMatchSelectWidth={false} // 虚拟滚动会出现空白，关闭
        onSearch={debounceFetcher}
        dropdownClassName={cx('dk-select-input-dropdown')}
        style={props.styles}
        optionLabelProp="label"
        filterOption={false}
        notFoundContent={
          fetching ? (
            <EmptyRender loading={true} />
          ) : (
            <div style={{ textAlign: 'center', padding: 4, color: '#bbb' }}>
              {intl.t('暂无数据')}
            </div>
          )
        }
        {...other}
        getPopupContainer={
          props.getPopupContainer
            ? props.getPopupContainer
            : (trigger) => trigger.parentNode
        }
        onChange={(v) => {
          onChange(v);
          setInputValue(v);
        }}
        onFocus={onFocus}
      >
        {options.map((v, i) => (
          <Option
            data={v}
            key={i}
            value={v?.value}
            className={cx('option-li', {
              active: currentId === v.value,
            })}
            label={v?.label?.replace(/§§/g, '')}
          >
            {' '}
            {v.label.length > 22 ? (
              <Tooltip title={v?.label?.replace(/§/g, '')}>
                {renderNodeItem(v)}
              </Tooltip>
            ) : (
              renderNodeItem(v)
            )}
            {currentId === v.value && (
              <i
                className={cx('dk-icon-gou', 'dk-iconfont', 'check-icon')}
              ></i>
            )}
          </Option>
        ))}
      </Select>
    </div>
  );
}
