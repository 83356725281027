/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-09-04 11:10:31
 * @LastEditTime: 2023-09-13 16:44:59
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/common/CooperMemberSelector/index.js
 *
 */
import React from 'react';
import { intl } from 'di18n-react';
import { searchAddTeamMember } from '@/service/cooper/cooperFile';
import MemberSearch from './member-search';
import classBind from 'classnames/bind';
import styles from './style.module.less';

const cx = classBind.bind(styles);


function CooperMemberSelector(props) {
  const searchFun = async (params) => {
    const data = await searchAddTeamMember(params);
    return data;
  };
  return (
    <div className={cx('cooper-member-selector')}>
      <MemberSearch
        placeholder={intl.t('请输入用户名或D-chat群组、Cooper团队、OE组')}
        searchFun={searchFun}
        {...props}
      />
    </div>
  );
}

export default CooperMemberSelector;
