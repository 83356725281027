/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-06-14 19:19:30
 * @LastEditTime: 2023-06-20 16:55:10
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/CreateFolder/getPathInfo.js
 *
 */
import { intl } from 'di18n-react';
import { get } from '@/utils/request/cooper';
import { getCrumbs } from '@/service/cooper/index';
import api from '@/utils/request/api/CooperApi';
import { TEAM_REG_LONG } from '@/constants/reg';

function getTeamDetail(id) {
  return get(api.TEAM_DETAIL.replace(':teamId', id));
}

function getPathInfo() {
  const { pathname } = window.location;

  // 个人空间
  let m = pathname.match(/^\/files\/(\d+)$/);

  if (m) {
    return getCrumbs(m[1]).then((res) => {
      const list = res.items;
      return {
        teamId: 0,
        parentId: list[list.length - 1].id,
        parentName:
          `${intl.t('个人空间')
          }/${
            list
              .slice(1)
              .map((r) => r.name)
              .join('/')}`,
      };
    });
  }

  // 团队空间
  // m = pathname.match(/^\/team-file\/(\d+)\/?(\d*)$/);
  m = pathname.match(TEAM_REG_LONG);

  if (m) {
    return getTeamDetail(m[1]).then((team) => {
      if (!m[2] || m[2] === 'home') {
        return {
          teamId: team.id,
          parentId: 0,
          parentName: `${intl.t('团队空间')}/${team.name}`,
          permission: team.permission,
        };
      }
      return getCrumbs(m[2]).then((res) => {
        const list = res.items;
        return {
          teamId: team.id,
          parentId: list[list.length - 1].id,
          parentName:
              `${intl.t('团队空间')
              }/${
                list
                  .slice(1)
                  .map((r) => r.name)
                  .join('/')}`,
          permission: team.permission,
        };
      });
    });
  }

  return Promise.resolve({
    teamId: 0,
    parentId: 0,
    parentName: intl.t('个人空间'),
  });
}

export default getPathInfo;
