import React from 'react';
import mountAnywhere from '@/utils/mountAnywhere';
import CreateFolder from './main';
import store, { history } from '@/model';
import { useDispatch, Provider } from 'react-redux';

function createFolder(doneCallback) {
  let close = () => { };

  const Comp = <Provider store={store}>
    <CreateFolder
      doneCallback={doneCallback}
      onClose={() => close()} />
  </Provider>;

  close = mountAnywhere(Comp);
}

export default createFolder
