/*
 * @Date: 2024-03-26 15:46:39
 * @LastEditors: guanzhong guanzhong@didiglobal.com
 * @LastEditTime: 2024-04-28 19:46:44
 * @FilePath: /knowledgeforge/src/utils/BPM.js
 * @Description: 此处是存放BPM相关的接口地址和环境地址
 */
import { getUserNameFromCookie } from '@/utils';
import { v4 as uuid } from 'uuid';

const initPostData = (data) => {
  const { cooperateType, team } = data
  const { name, check, permission } = team
  return {
    processDefinitionKey: 'Space_Externalization_Approval_Process',
    businessKey: `Space_Externalization_Approval_Process${uuid()}`,
    name: 'Cooper-外部空间开通申请',
    tenantId: 'cooper',
    variables: [
      {
        name: 'name',
        type: 'string',
        value: name,
      },
      {
        name: 'cooperateType',
        type: 'string',
        value: cooperateType,
      },
      {
        name: 'check',
        type: 'boolean',
        value: check,
      },
      {
        name: 'permission',
        type: 'long',
        value: permission,
      },
    ],
    outcome: 'submit',
    comment: '测试审批',
  }
}


const API = {
  test: {
    webLink: 'http://bpm-test.didichuxing.com',
    serverLink: 'https://api-kylin-xg02.intra.xiaojukeji.com/flow_api_test',
    token: 'ffu433T4^734',
  },
  qa: {
    webLink: 'http://bpm-stage.ectest.intra.xiaojukeji.com',
    serverLink: 'https://api-kylin.intra.xiaojukeji.com/flow_api_stage',
    token: 'ffu433T4^734',
  },
  prod: {
    webLink: 'http://bpm.didichuxing.com',
    serverLink: 'https://cooper.didichuxing.com/bpm/flow',
    token: 'cooper@eo#14%',
  },
}

let webLink = null;
let serverLink = null;
let token = null;
if (window.location.hostname === 'localhost' || window.location.hostname === 'cooper-test.didichuxing.com') {
  webLink = API.test.webLink;
  serverLink = API.test.serverLink;
  token = API.test.token
} else if (window.location.hostname === 'cooper-qa.didichuxing.com') {
  webLink = API.qa.webLink;
  serverLink = API.qa.serverLink;
  token = API.qa.token
} else {
  webLink = API.prod.webLink;
  serverLink = API.prod.serverLink;
  token = API.prod.token
}

// eslint-disable-next-line import/prefer-default-export, consistent-return
export const createOutSpaceInBPM = async (data) => {
  try {
    let res = null
    await fetch(`${serverLink}/rest/process-instances/startAndSaveFormByVariables`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token,
        username: getUserNameFromCookie(),
      },
      body: JSON.stringify({
        ...initPostData(data),
      }),
    }).then((Data) => {
      res = Data.json();
    }).catch((err) => {
      console.log(err);
    })
    return res;
  } catch (err) {
    console.log(err);
  }
}

export const bpmWebLink = webLink;
