/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-04 15:50:42
 * @LastEditTime: 2024-05-06 15:03:48
 * @Description: 创建团队空间
 * @FilePath: /knowledgeforge/src/components/CooperOperation/CreateTeam/index.js
 *
 */
import mountAnywhere from '@/utils/mountAnywhere';
import PrimaryModal from '@/components/common/PrimaryModal';
import { getLocale, intl } from 'di18n-react';
import classBind from 'classnames/bind';
import { apolloSwitch } from '@/utils/ab';
import { useEffect, useState } from 'react';
import { useDispatch, Provider, useSelector } from 'react-redux';
import { Input, Radio, Button, message, Checkbox, Tooltip, Modal, Popover } from 'antd';
import { checkInputValue } from '@/utils/cooperutils';
import { get, post } from '@/utils/request/cooper';
import CooperApi from '@/utils/request/api/CooperApi';
import store, { history } from '@/model';
import { CreateTeamSpace, CreateTeamSpaceEn } from '@/assets/icon/index';
import DropdownCheckbox from '@/components/common/DropdownCheckbox';
import CooperMemberSelector from '@/components/common/CooperMemberSelector';
import { MEMBER_TYPE } from '@/constants/cooperConstants';
import { getCookie, isDC } from '@/utils';
import styles from './style.module.less';
import { initTeamSpaceList } from '@/components/CooperTeamList/index'
import { createOutSpaceInBPM } from '@/utils/BPM';
// import PermissionsPop from '@/components/PermissionsPop';

const cx = classBind.bind(styles);

const teamTypeMap = {
  private: {
    label: () => intl.t('私密-需要验证'),
    detail: () => intl.t('加入团队需要通过管理员审核'),
    disabled: false,
    value: 'private',
  },
  public: {
    label: () => intl.t('公开-无需验证'),
    detail: () => intl.t('任何人都可以通过链接加入'),
    disabled: false,
    value: 'public',
  },
}

const teamSpaceTypeMap = {
  innerTeamSpace: {
    label: () => intl.t('内部空间'),
    detail: () => intl.t('公司内部文档协作空间'),
    value: 'IN',
  },
  outTeamSpace: {
    label: () => intl.t('外部空间'),
    detail: () => intl.t('与企业外用户文档协作空间'),
    value: 'IN_OUT',
  },
}

const checkTeamExist = async (data) => {
  const { name, cooperateType } = data
  const res = await get(`${CooperApi.TEAM_EXIST}?team=${encodeURIComponent(name)}&teamType=${cooperateType}`);
  return res
}

const joinTeam = async (teamName) => {
  const data = await post(CooperApi.APPLYU_TEAM_JOIN.replace(':teamName', teamName), {
    message: '',
  });
  return data
}

export const teamPermis = function () {
  return [
    {
      label: intl.t('查看'),
      id: 0,
      value: '1',
      perm: 1,
      desc: intl.t('用户可以查看团队中文件、文件夹以及在线协作文档'),
      disabled: true,
    },
    {
      label: intl.t('编辑'),
      id: 1,
      value: '2',
      perm: 2,
      desc: intl.t('用户可以对团队中文件、文件夹进行重命名，针对在线协作文档进行修改编辑'),
    },
    {
      label: intl.t('上传'),
      id: 2,
      value: '8',
      perm: 8,
      desc: intl.t('用户可以在团队中上传文件、新建文件夹和在线协作文档'),
    },
    {
      label: intl.t('分享'),
      id: 3,
      value: '16',
      perm: 16,
      desc: intl.t('用户可以对文件、文件夹进行分享，针对在线协作文档用户可以添加邀请协作者'),
    },
    {
      label: intl.t('下载'),
      id: 4,
      value: '32',
      perm: 32,
      desc: intl.t('用户对团队中所有的文件、文件夹进行下载，针对在线协作文档可以导出'),
    },
  ];
};

const checkOptions = function () {
  return [
    {
      label: intl.t('首页'),
      value: 'home',
      desc: intl.t('展示重要信息进行团队透传'),
      disabled: false,
      function: 'HOME',
      enable: true,
      force: false,
      nameCn: '主页',
      nameEn: 'Home',
      subjectId: null,
      displayName: null,
    },
    {
      label: intl.t('文件'),
      value: 'file',
      desc: intl.t('云盘存储和在线协作、聚合团队知识内容'),
      disabled: true,
      function: 'FILE',
      enable: true,
      force: false,
      nameCn: '文件',
      nameEn: 'Files',
      subjectId: null,
      displayName: null,
    },
  ]
};

function CreacteteamRender(props) {
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [tName, setTname] = useState('')
  const [errorText, setErrorText] = useState('')
  const [tType, setTtype] = useState('private')
  const [tPermis, setTpermis] = useState([0, 1, 2])
  const [mbChose, setMbChose] = useState([])
  const [isCanCreateOutSpace, setIsCanCreateOutSpace] = useState(false)
  // const [mbRecent, setMbRecent] = useState([])
  // const [mbUsers, setMbUsers] = useState([])
  // const [mbTeams, setMbTeams] = useState([])
  const [teamType, setTeamType] = useState(teamTypeMap)
  const [teamSpaceType] = useState(teamSpaceTypeMap)
  const [teamSpace, setTeamSpace] = useState(teamSpaceType.innerTeamSpace.value)
  const curLocale = getLocale();
  const [moduleCheckOptions, setModuleCheckOptions] = useState(checkOptions);
  const [usernameGray, setUsernameGray] = useState(false);
  const { cooperLinkConf } = useSelector((state) => state.GlobalData)

  const { activeOwnerType } = useSelector((s) => s.CooperTeamList);

  // eslint-disable-next-line camelcase, max-len
  const { out_space_explain_zh, out_space_explain_en, approve_explain_zh, approve_explain_en, action_explain_zh, action_explain_en } = cooperLinkConf
  useEffect(() => {
    initGrey();
  }, []);

  const initGrey = async () => {
    const data = await apolloSwitch('Space_Knowledge');
    const data1 = await apolloSwitch('cooper-tenant')
    setIsCanCreateOutSpace(data1)
    setUsernameGray(data);
  }


  const doCreateTeam = async (name, check) => {
    let p = 0;
    tPermis.forEach((v) => {
      p += teamPermis.call(this)[v].perm;
    });
    const invitations = mbChose.map((m) => ({
      id: m.id,
      inviteeType: m.type,
      permission: p,
    }));

    const d = {
      team: {
        name,
        check,
        permission: p,
      },
      invitations: teamSpace === teamSpaceType.outTeamSpace.value ? [] : invitations,
      limitCount: 500,
      cooperateType: teamSpace,
    }

    if (usernameGray && teamSpace !== teamSpaceType.outTeamSpace.value) {
      const funcs = moduleCheckOptions.map((option) => {
        return {
          function: option.function,
          enable: option.enable,
          force: option.force,
          nameCn: option.nameCn,
          nameEn: option.nameEn,
          subjectId: option.subjectId,
          displayName: option.displayName,
        }
      })
      d.spaceFunctions = funcs;
    }
    if (teamSpace === teamSpaceType.outTeamSpace.value) {
      const BPMData = await createOutSpaceInBPM(d)
      if (BPMData?.startUserTaskUrl) {
        if (isDC()) {
          window.open(BPMData?.startUserTaskUrl, '_blank');
        } else {
          window.location.href = BPMData?.startUserTaskUrl;
          window.history.pushState({}, '', window.location.href);
        }
      } else {
        message.error(intl.t('创建失败'));
      }
    } else {
      const data = await post(CooperApi.CREATE_TEAMS, d);
      message.success(intl.t('创建成功'));
      return data;
    }
  }

  const doCreate = async () => {
    if (tName.trim() === '') {
      message.error(intl.t('团队名称不能为空'));
      return
    }
    setConfirmLoading(true)
    if (teamSpace === teamSpaceType.outTeamSpace.value) {
      let check = tType === 'private'
      Modal.confirm({
        centered: true,
        title: intl.t('申请创建外部空间'),
        icon: null,
        className: 'out-space-double-ok',
        content: (
          <div className={cx('modal-content')}>
            <p>
              {intl.t('外部空间的创建需通过相关审批后方可实现，具体审批流程请见')}
              <span
                className={'link-des'}
                onClick={() => {
                  // eslint-disable-next-line camelcase
                  let link = getLocale() === 'en-US' ? approve_explain_en : approve_explain_zh;
                  window.open(link, '_black');
                }}> {intl.t('审批说明')}</span>
            </p>
            <p>
              {intl.t('一旦创建成功，当前团队空间会成为“外部”团队空间，空间管理员可以邀请企业外的合作伙伴加入空间进行文档协作与分享，')}
              <span
                className={'link-des'}
                onClick={() => {
                  // eslint-disable-next-line camelcase
                  let link = getLocale() === 'en-US' ? action_explain_en : action_explain_zh;
                  window.open(link, '_black');
                }}>{intl.t('详情请见功能说明')}</span>
            </p>
          </div>
        ),
        okText: intl.t('申请创建'),
        cancelText: intl.t('取消'),
        closable: true,
        onOk: async () => {
          await doCreateTeam(tName, check)
          props.onClose && props.onClose()
          initTeamSpaceList && initTeamSpaceList(activeOwnerType); // 目前的创建场景会刷新页面
        },
      })
    } else {
      let check = tType === 'private'
      const teamData = await doCreateTeam(tName, check)
      props.onClose && props.onClose()
      history.push(`/team-file/${teamData.team_id}/home`)
    }
  }

  const toJoinTeam = async () => {
    const { check } = await joinTeam(tName.trim());
    if (check) {
      message.info(intl.t('已提交，请等待审批'));
    } else {
      message.success(intl.t('加入成功'));
    }

    props.onClose && props.onClose();
  }

  const switchType = (type) => {
    if (type.disabled) return
    if (tType !== type.value) {
      setTtype(type.value)
    }
    if (type.value === 'public') {
      // 公开团队默认勾选所有权限
      setTpermis([0, 1, 2, 3, 4]);
    } else {
      // 私有团队默认勾选查看、编辑、上传
      setTpermis([0, 1, 2]);
    }
  }

  const switchSpaceType = (type) => {
    if (teamSpace !== type) {
      setTeamSpace(type)
    }
    if (type === teamSpaceType.outTeamSpace.value) {
      setTtype('private')
      setTpermis([0, 1, 2]);
      setTeamType({
        ...teamType,
        public: {
          ...teamType.public,
          disabled: true,
        },
      })
      tName.trim() !== '' && changeName('', type)
    } else {
      setTtype('private');
      setTpermis([0, 1, 2]);
      setTeamType({
        ...teamType,
        public: {
          ...teamType.public,
          disabled: false,
        },
      })
      tName.trim() !== '' && changeName('', type)
    }
  }

  const changeName = async (e, type) => {
    let tNameTemp = (e && e.target.value.substr(0, 200));
    if (type) {
      tNameTemp = tName
    }
    setTname(tNameTemp)
    let s = checkInputValue(tNameTemp);
    // let usernamezh = getCookie('_cooper_username_zh');
    let arr = ['My Space', '个人空间', `${getCookie('_cooper_username')}'s Personal Space`, `${getCookie('_cooper_username_zh')}的个人空间`]
    if (arr.includes(tNameTemp)) {
      setErrorText(intl.t("'个人空间'为系统预设空间，不允许创建"));
      return;
    }

    setErrorText(s)
    let isExistData = await checkTeamExist({
      name: tNameTemp || tName,
      cooperateType: type || teamSpace,
    })
    // eslint-disable-next-line no-shadow
    const { isExisted, code, message } = isExistData
    if (isExisted) {
      if (code === 1) {
        setErrorText(-1)
      } else {
        setErrorText(message)
      }
    }
  }

  const changePermis = (value, newOptions) => { // eslint-disable-line no-unused-vars
    const p = value[0] === 0 ? value : [0].concat(value);
    setTpermis(p)
  };

  const chooseMember = (value) => {
    setMbChose(value)
    // setMbRecent([])
    // setMbTeams([])
    // setMbUsers([])
  }

  const clickItem = (member) => {
    const EventMapOmega = {
      [MEMBER_TYPE.OE]: 'ep_team_create_addoe_ck',
      [MEMBER_TYPE.COOPER]: 'ep_team_create_addcooper_ck',
      [MEMBER_TYPE.MAIL]: 'ep_team_create_addemail_ck',
      [MEMBER_TYPE.DC]: 'ep_team_create_adddc_ck',
    };
    window.__OmegaEvent(EventMapOmega[member.type], '', {
      platform: 'new',
    });
  };
  const goToOutSpaceLink = () => {
    // eslint-disable-next-line camelcase
    let link = getLocale() === 'en-US' ? out_space_explain_en : out_space_explain_zh;
    window.open(link, '_blank');
  }
  const setModuleCheckValue = (value) => {
    const res = moduleCheckOptions.map((option) => {
      if (option.value === value.value) {
        if (value.value === 'home') {
          if (option.enable) { // new state
            window.__OmegaEvent('ep_team_home_create_homepage_cancel_ck', '团队空间首页-创建团队空间-取消首页');
          } else {
            window.__OmegaEvent('ep_team_home_create_homepage_ck', '团队空间首页-创建团队空间-选择首页');
          }
        }
        option.enable = !option.enable
      }
      return option;
    });
    setModuleCheckOptions(res);
  }

  return (
    <PrimaryModal
      title=""
      width={800}
      onCancel={props.onClose}
      confirmLoading={confirmLoading}
      footer={null}
      selfClassName="create-team-space"
    >
      <div className={cx('create-team-content')}>
        <div className={cx('create-team-content-left')}>
          <div className={cx('title')}>{intl.t('创建团队空间')}</div>
          <div className={cx('create-team-content-left-body')}>
            {
              isCanCreateOutSpace && <div className={cx('team-space-type')}>
                <p>{intl.t('空间类型')}</p>
                <div className={cx('team-space-type-box')}>
                  <div
                    className={cx({
                      'div-active': teamSpace === teamSpaceType.innerTeamSpace.value,
                    })}
                    onClick={() => switchSpaceType('IN')}>
                    <Radio checked={teamSpace === teamSpaceType.innerTeamSpace.value} />
                    <div className={cx('team-space-type-box-item')}>
                      <span className={cx('label')}>{teamSpaceType.innerTeamSpace.label()}</span>
                      <span className={cx('des')}>{teamSpaceType.innerTeamSpace.detail()}</span>
                    </div>
                  </div>
                  <div
                    className={cx({
                      'div-active': teamSpace === teamSpaceType.outTeamSpace.value,
                    })}
                    onClick={() => switchSpaceType('IN_OUT')}>
                    <Radio checked={teamSpace === teamSpaceType.outTeamSpace.value} />
                    <div className={cx('team-space-type-box-item')}>
                      <span className={cx('label')}>
                        {teamSpaceType.outTeamSpace.label()}
                        <Popover
                          placement='top'
                          zIndex={1000}
                          overlayClassName='dk-ant-popover__reset'
                          content={() => {
                            return (<div className={cx('out-team-space-details')}>
                              {intl.t('查看')}
                              <span onClick={() => goToOutSpaceLink()}> {intl.t('外部空间说明')}</span>
                            </div>)
                          }}>
                          <i
                            className={cx({
                              'dk-iconfont': true,
                              'dk-icon-shuoming3px': true,
                              'permission-tip': true,
                            })} />
                        </Popover>
                      </span>
                      <span className={cx('des')}>{teamSpaceType.outTeamSpace.detail()}</span>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className={cx({ 'team-name': true, required: true })}>
              <p>{intl.t('空间名称')}</p>
              <Input
                placeholder={intl.t('输入团队名称')}
                value={tName}
                onChange={changeName}
                maxLength={200}
                allowClear
                className={cx('team-name-input')}
              />
              {
                errorText
                && (
                  errorText === -1
                    ? (
                      <span className={cx('tips')}>
                        <i className={cx('dk-iconfont', 'dk-icon-cuowu', 'cuowu-tip', 'icon-error')} />
                        <span className={cx('tips-left')}>“{tName}”{intl.t('团队已存在')}{' '}</span>
                        <a
                          href='#'

                          onClick={(e) => {
                            e.preventDefault();
                            toJoinTeam();
                          }}
                          className={cx('tips-right')}
                        >
                          {intl.t('申请 ”{title}“ 团队空间权限', {
                            title: tName,
                          })}
                        </a>
                      </span>
                    )
                    : (
                      <span> <i className={cx('dk-iconfont', 'dk-icon-cuowu', 'cuowu-tip', 'icon-error')} /> {errorText}</span>
                    )
                )
              }
            </div>
            <div className={cx('team-type')}>
              <p>{intl.t('空间可见性')}</p>
              <div>
                <div
                  onClick={() => switchType(teamType.private)}
                  className={cx('private')}>
                  <Radio
                    checked={tType === teamType.private.value}
                    disabled={teamType.private.disabled}/>
                  <span className={cx('label')}>{teamType.private.label()}</span>
                  <span className={cx('des')}>{teamType.private.detail()}</span>
                </div>
                <Tooltip
                  title={!teamType.public.disabled ? '' : intl.t('外部空间不支持设为公开')}
                  placement='topLeft'
                  >
                  <div
                    onClick={() => switchType(teamType.public)}
                    className={cx({
                      public: true,
                      disabled: teamType.public.disabled,
                    })}>
                    <Radio
                      checked={tType === teamType.public.value}
                      disabled={teamType.public.disabled}
                    />
                    <span className={cx('label')}>{teamType.public.label()}</span>
                    <span className={cx('des')}>{teamType.public.detail()}</span>
                  </div>
                </Tooltip>
              </div>
            </div>
            <div
              className={cx({
                'team-permis': true,
                required: true,
              })}>
              <p>
                {intl.t('成员初始权限')}
                {/* <PermissionsPop /> */}
              </p>
              <DropdownCheckbox
                overlayClassName={'team-permis-create'}
                showDesc={true}
                type={'bg'}
                options={teamPermis.call(this)}
                value={tPermis}
                placement={'left'}
                onChange={(value, newOptions) => changePermis(value, newOptions)
                }
              />
            </div>
            {teamSpace !== teamSpaceType.outTeamSpace.value && usernameGray && (
              <div
                className={cx({
                  'team-module': true,
                  required: true,
                })}>
                <p>{intl.t('初始模块')}</p>
                <div className={cx('team-module-content')}>
                  {
                      moduleCheckOptions.map((item, index) => {
                        if (item.disabled) {
                          return (
                            <Tooltip
                              title={intl.t('默认模块，不支持取消勾选')}
                              placement='topLeft'
                            >
                              <Checkbox
                                key={index}
                                checked={item.enable}
                                disabled={item.disabled}
                                value={item.value}
                                onChange={() => setModuleCheckValue(item)}
                                className={cx('team-module-item')}
                              // label={item.label}
                              >
                                <span className={cx('team-module-item-label')}>{item.label}</span>
                                <span className={cx('team-module-item-desc')}>{item.desc}</span>
                              </Checkbox>
                            </Tooltip>
                          )
                        }
                        return (
                          <Checkbox
                            key={index}
                            checked={item.enable}
                            disabled={item.disabled}
                            value={item.value}
                            onChange={() => setModuleCheckValue(item)}
                            className={cx('team-module-item')}
                          // label={item.label}
                          >
                            <span className={cx('team-module-item-label')}>{item.label}</span>
                            <span className={cx('team-module-item-desc')}>{item.desc}</span>
                          </Checkbox>
                        )
                      })
                    }
                </div>
              </div>
            )
            }
            {teamSpace !== teamSpaceType.outTeamSpace.value && <div className={cx('team-add-member')}>
              <p>{intl.t('添加成员')}</p>
              <CooperMemberSelector
                value={mbChose}
                onChange={chooseMember}
                onClickItem={clickItem}
              />
            </div>}
          </div>

        </div>
        <img
          src={curLocale === 'en-US' ? CreateTeamSpaceEn : CreateTeamSpace}
          className={cx('create-team-content-right')} />
        <div className={cx('action')}>
          <Button
            className={cx('cancel')}
            onClick={() => props.onClose()}>{intl.t('取消')}</Button>
          <Button
            type='primary'
            disabled={errorText}
            onClick={doCreate}>{intl.t('新建')}</Button>
        </div>
      </div>
    </PrimaryModal>
  )
}


function CreateTeam(doneCallback) {
  let close = () => { };

  const Comp = (
    <Provider store={store}>
      <CreacteteamRender
        doneCallback={doneCallback}
        onClose={() => close()} />
    </Provider>
  );

  close = mountAnywhere(Comp);
}

export default CreateTeam
