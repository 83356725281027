import { intl } from 'di18n-react';
import classNames from 'classnames/bind';
import { Checkbox, Col, Row } from 'antd';
import styles from './style.module.less';
import { useState, useEffect, useCallback, useMemo } from 'react';

const cx = classNames.bind(styles);

function MultiChoose(props) {
  const {
    defaultOptions,
    changeChooseList,
    removeChooseItem,
    overlayClassName,
    children,
  } = props;
  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions])


  const removeOptions = (k) => {
    const newOps = options.filter((item) => item.value !== k);
    setOptions(newOps);
    removeChooseItem && removeChooseItem(k)
  }

  const changeCheckbox = (v) => {
    changeChooseList && changeChooseList(v);
    let res = [...options];
    res.forEach((element) => {
      if (v.includes(element.value)) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
    setOptions(res)
  }

  const checkedArray = useMemo(() => {
    let res = [];
    options.forEach((element) => {
      if (element.checked) res.push(element.value)
    });
    return res;
  }, [options]);

  return (
    <div className={cx('multi-choose-wrap', overlayClassName)} >
      {options.length > 0 && <Checkbox.Group
        onChange={changeCheckbox}
        defaultValue={checkedArray}
        value={checkedArray}
      >
        <div className={cx('options-content')}>
          {
            options.map((item) => {
              return (
                <div
                  className={cx('options-item')}
                  key={item.value}>
                  <Checkbox value={item.value}>
                    <div className={cx('item-content-wrap')}>
                      {item.iconImage && <img
                        className={cx('image')}
                        src={item.iconImage} />}
                      <p className={cx('text')}>{item.label}</p>
                    </div>
                  </Checkbox>
                  {!item.isPreset && <div className={cx('close-options-icon')}>
                    <i
                      onClick={() => removeOptions(item.value)}
                      className={cx(
                        'dk-iconfont',
                        'dk-icon-chouti-guanbi',
                        'remove-icon',
                      )} />
                  </div>}
                </div>
              )
            })
          }
        </div>

      </Checkbox.Group>}

      {children}
    </div>
  );
}

export default MultiChoose;
