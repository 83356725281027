import EmptySearch from '@/assets/icon/empty6.png';
import ErrorTips from '@/components/ErrorTips';
import NoMore from '@/components/NoMore';
import SearchItem from '@/components/SearchItem';
import SpinRender from '@/components/SpinRender';
import { PAGE_SIZE } from '@/constants';
import { FileType } from '@/constants/cooper';
import { getSearchTypeMap } from '@/constants/search';
import { getSearchData } from '@/service/knowledge/search';
import { formateDesc, getUserNameFromCookie, isDesktopDC } from '@/utils';
import classBind from 'classnames/bind';
import { intl } from 'di18n-react';
import { debounce } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import GroupContent from '../GroupContent';
import HistoryResultList from '../HistoryResultList';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const EmptyRender = ({ text }) => (
  <ErrorTips
    title={text || intl.t('未搜索到相关结果，请更换关键词')}
    overlayClassName={'search-page-empty'}
    img={EmptySearch}
  />
);

const Result = (props) => {
  const { api, searchType, isRecent, onClickMore, filter, keyWord } = props;
  const [metisData, setMetisData] = useState({
    data: [],
    type: 'metis',
  });
  const [spaceData, setSpaceData] = useState({
    data: [],
    type: 'space',
  });
  const [searchData, setSearchData] = useState({
    data: [],
    type: null,
  });
  const [total, setTotal] = useState({ page: 0, metis: 0, space: 0 });
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const searchTypeMap = getSearchTypeMap();
  const [traceId, setTraceId] = useState(null);

  useEffect(() => {
    if (isRecent) return;
    setHasMore(true);
    setTotal({ page: 0, metis: 0, space: 0 });
    setLoading(true);
    setSearchData({
      data: [],
      type: null,
    });
    setMetisData({
      data: [],
      type: 'metis',
    });
    setSpaceData({
      data: [],
      type: 'space',
    });
    getSearchList(0, [], [], []);

    return () => {
      getSearchList.cancel();
    };
  }, [filter, searchType, keyWord]);

  const getSearchList = debounce(
    async (
      pageNum = 0,
      initData,
      initMetisData,
      initSpaceData,
    ) => {
      let currentData = initData ?? searchData.data;
      let currentMetisData = initMetisData ?? metisData.data;
      let currentSpaceData = initSpaceData ?? spaceData.data;
      // setLoading(true);

      let opts = {
        pageNum,
        pageSize: PAGE_SIZE,
        key: keyWord?.trim(),
        searchType: searchTypeMap[searchType]?.type,
      };

      // 有的tab下无对应的filter，但需要维持filter中的数据切换回来还存在
      searchTypeMap[searchType].filterOption.forEach((v, i) => {
        if (v === 'position') opts.locationIds = [...filter.locationIds];
        if (v === 'module') opts.moduleTypes = [...filter.moduleTypes];
        if (v === 'tags' && filter.tagIds) opts.tagIds = [...filter.tagIds];
        if (v === 'times') opts.time = filter.time;
        if (v === 'owner') {
          opts.resourceOwners = filter?.resourceOwners?.map((item) => {
            if (item.ldap.includes('@')) {
              return {
                ...item,
                ldap: item.ldap.split('@')[0],
              }
            }
            return item
          });
          opts.owners = filter.owners.map((item) => {
            if (item.includes('@')) {
              return item.split('@')[0]
            }
            return item
          })
        }
        if (v === 'fileType') opts.fileType = filter.fileType;
      });

      let res = api.search ? await api.search(opts) : await getSearchData(opts);
      window.__OmegaEvent('ep_search_ck', '搜索');

      setTraceId(res?.traceId);

      // window.__OmegaEvent('ep_dkpc_dk_advancedsearch_ck', '搜索', {
      //   searchtype: searchType,
      //   key: keyWord,
      //   time: filter.time,
      //   owner: filter.owner,
      // });

      const omegaAttrs = {
        ldap: getUserNameFromCookie(),
        origin: 2,
        keyword: keyWord,
        tabtype: searchTypeMap[searchType]?.type,
      }

      // traceId 用于追踪搜索数据
      if (res?.traceId) {
        omegaAttrs.traceid = res.traceId;
      }

      // 位置
      if (filter.locationIds && filter.locationIds.length > 0) {
        omegaAttrs.location = filter.locationIds;
      }

      // 模块
      if (filter.moduleTypes && filter.moduleTypes.length > 0) {
        omegaAttrs.module = filter.moduleTypes.toString();
      }

      // 模块
      if (filter.owners && filter.owners.length > 0) {
        omegaAttrs.owner = filter.owners.map((item) => {
          if (item.includes('@')) {
            if (item.split('@')[0] === getUserNameFromCookie()) {
              return 'my'
            }
            return item.split('@')[0];
          }
          return item;
        })
      }

      // 标签
      if (filter.tagIds && filter.tagIds.length > 0) {
        omegaAttrs.label = filter.tagIds;
      }

      // 最近浏览时间
      if (filter.time) {
        omegaAttrs.recentlyviewed = filter.time;
      }

      if (filter.fileType) {
        omegaAttrs.filetype = filter.fileType;
      }

      window.__OmegaEvent('ep_cooper_search_data_ck', '搜索', omegaAttrs);

      // 数据检查,这里检查知识库是因为搜索类型为全部的时候需要知识库
      if (
        !Array.isArray(res?.[searchType]?.items)
        || !Array.isArray(res?.metis?.items)
        || !Array.isArray(res?.space?.items)
      ) {
        setLoading(false);
        return;
      }

      // 数据记录type，解决tab和数据不对应的情况处理 --- 频繁切换Tab时
      const { searchType: searchTypeNum } = res;

      const searchTypeStr = Object.keys(searchTypeMap).filter((item) => searchTypeMap[item].type === searchTypeNum)[0]
      // metis 数据格式处理
      if (searchTypeStr === 'allPages' || searchTypeStr === 'metis') {
        let metisDatatmp = res?.metis?.items.map((v) => {
          return {
            ...v,
            iconImage: v?.resourceTinyImage,
            scopeName: v?.filePath,
            resourceType: v?.resourceType,
            teamId: v?.spaceType === FileType.SPACE ? v?.spaceId : null,
            markedQuickVisit: v?.markedQuickVisit || false,
            modifyTime: v?.modifyTime,
            desc: v?.resourceDesc,
            knowledgeId: v?.resourceId,
            parentId: v?.parentId,
            resourceOwner: [FileType.KNOWLEDGE, FileType.SPACE, FileType.PERSONAL_SPACE].includes(v?.resourceType)
              ? v?.spaceOwnerName
              : v?.resourceOwner,
          };
        });
        setMetisData({
          ...metisData,
          data: [...currentMetisData, ...metisDatatmp],
        });
      }

      if (searchTypeStr === 'allPages' || searchTypeStr === 'space') {
        let spaceDatatmp = res?.space?.items.map((v) => ({
          ...v,
          iconImage: v?.resourceTinyImage,
          scopeName: v?.filePath,
          resourceType: v?.resourceType,
          teamId: v?.spaceType === FileType.SPACE ? v?.spaceId : null,
          markedQuickVisit: v?.markedQuickVisit || false,
          modifyTime: v?.modifyTime,
          desc: v?.resourceDesc,
          spaceId: v?.resourceId,
          parentId: v?.parentId,
          resourceOwner: [FileType.KNOWLEDGE, FileType.SPACE, FileType.PERSONAL_SPACE].includes(v?.resourceType)
            ? v?.spaceOwnerName
            : v?.resourceOwner,
        }));
        setSpaceData({
          ...spaceData,
          data: [...currentSpaceData, ...spaceDatatmp],
        });
      }

      // TODO:需要跳转到位置时，增加positionName并传parentId
      let searchRes = res?.[searchTypeStr]?.items.map((v) => {
        return {
          ...v,
          scopeName: v?.filePath,
          resourceType: v?.resourceType,
          teamId: v?.spaceType === FileType.SPACE ? v?.spaceId : null,
          markedQuickVisit: v?.markedQuickVisit || false,
          modifyTime: v?.modifyTime,
          parentId: v?.parentId,
          desc:
            v?.resourceDesc || v?.description
              ? formateDesc(
                v?.resourceDesc || v?.description,
                document.getElementById('result-wrap')?.clientWidth - 85,
                keyWord?.length ?? 0,
              )
              : undefined,
          resourceOwner: [FileType.KNOWLEDGE, FileType.SPACE, FileType.PERSONAL_SPACE].includes(v?.resourceType)
            ? v?.spaceOwnerName
            : v?.resourceOwner,
          knowledgeId: v?.spaceId,
          dkShareType: v?.shareType, // 实际不会搜出来知识库分享页面数据
          pageId: v?.resourceId,
          shareId: v?.manageQuickAccessInfo?.shareId || v?.shareId,
          shareLink: v?.shareLink,
          isShareWithMe: v?.isShareWithMe,
        };
      });

      if (res[searchTypeStr].items.length < PAGE_SIZE) {
        setHasMore(false);
      }
      setSearchData({
        data: [...currentData, ...searchRes],
        type: searchTypeStr,
      });
      setTotal({
        page: res[searchTypeStr].totalCount,
        metis: res.metis.totalCount,
        space: res.space.totalCount,
      });
      setLoading(false);
    },
    500,
  );

  const afterClickItem = () => {
    !isDesktopDC && props.close();
    window.__OmegaEvent('ep_search_advanced_recently_visit_ck', '高级搜索-最近浏览-访问文件', {
      tabtype: searchType,
    });
  };

  const searchClickCallBack = (position, clickItem) => {
    const omegaAttrs = {
      ldap: getUserNameFromCookie(),
      origin: 2,
      keyword: keyWord?.trim() || '',
      tabtype: searchTypeMap[searchType]?.type,
      clickposition: position,
      resourcetype: clickItem.resourceType,
      spacetype: clickItem.spaceType,
      score: clickItem.score,
      result: clickItem.resourceId,
    }

    if (traceId) {
      omegaAttrs.traceid = traceId;
    }

    // 更新时间
    if (clickItem.modifyTime) {
      omegaAttrs.updatetime = clickItem.modifyTime;
    }

    // 浏览时间
    if (clickItem.visitTime) {
      omegaAttrs.browsetime = clickItem.visitTime;
    }

    // 位置
    if (filter.locationIds && filter.locationIds.length > 0) {
      omegaAttrs.location = filter.locationIds;
    }

    // 模块
    if (filter.moduleTypes && filter.moduleTypes.length > 0) {
      omegaAttrs.module = filter.moduleTypes.toString();
    }

    // 模块
    if (filter.owners && filter.owners.length > 0) {
      omegaAttrs.owner = filter.owners.map((item) => {
        if (item.includes('@')) {
          if (item.split('@')[0] === getUserNameFromCookie()) {
            return 'my'
          }
          return item.split('@')[0];
        }
        return item;
      })
    }

    // 标签
    if (filter.tagIds && filter.tagIds.length > 0) {
      omegaAttrs.label = filter.tagIds;
    }

    // 最近浏览时间
    if (filter.time) {
      omegaAttrs.recentlyviewed = filter.time;
    }

    if (filter.fileType) {
      omegaAttrs.filetype = filter.fileType;
    }

    window.__OmegaEvent('ep_cooper_search_list_ck', '点击搜索结果', omegaAttrs);
    window.__OmegaEvent('ep_search_advanced_visit_ck', '高级搜索-访问文件', {
      source: searchType,
    });
  }

  const totalNum = () => {
    if (searchType === 'metis') return total.metis;
    if (searchType === 'space') return total.space;
    if (searchType === 'allPages') { return total.metis + total.space + total.page; }
    return total.page;
  };

  return (
    <div
      className={cx('search-page-result', 'search-page-result-os-flag')}
      id="result-wrap"
    >
      {isRecent ? (
        <HistoryResultList
          afterClickItem={afterClickItem}
          api={api}
          type={searchTypeMap[searchType]?.type}
        />
      ) : (
        <div className='search-page-content-os-flag'>
          <>
            <p className={cx('search-page-total')}>
              {intl.t('共')}
              {totalNum()}
              {intl.t('条结果')}
            </p>

            {metisData.data.length + spaceData.data.length + searchData.data.length > 0 && (
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={getSearchList}
                hasMore={!loading && hasMore}
                useWindow={false}
              >
                {searchType === searchData.type && searchType === 'allPages' && (
                  <>
                    <GroupContent
                      onClickMore={onClickMore}
                      metisData={metisData.data}
                      spaceData={spaceData.data}
                      total={total}
                      isHasFile={searchData.data.length > 0}
                      api={api}
                      afterClickItem={searchClickCallBack}
                    />

                    {searchData.data.length > 0
                      && searchData.data.map((item, index) => (
                        <React.Fragment key={index}>
                          <SearchItem
                            position={index}
                            data={item}
                            callback={searchClickCallBack}
                            api={api}
                            wrapClass={cx('item-wrap')}
                          />
                        </React.Fragment>
                      ))}
                  </>
                )}

                {searchType === metisData.type && metisData.data.length > 0 && (
                  <>
                    {metisData.data.map((item, index) => (
                      <React.Fragment key={index}>
                        <SearchItem
                          data={item}
                          position={index}
                          callback={searchClickCallBack}
                          api={api}
                          wrapClass={cx('item-wrap')}
                        />
                      </React.Fragment>

                    ))}
                  </>
                )}

                {searchType === spaceData.type && spaceData.data.length > 0 && (
                  <>
                    {spaceData.data.map((item, index) => (
                      <React.Fragment key={index}>
                        <SearchItem
                          position={index}
                          data={item}
                          callback={searchClickCallBack}
                          api={api}
                          wrapClass={cx('item-wrap')}
                        />
                      </React.Fragment>

                    ))}
                  </>
                )}

                {searchType === searchData.type
                  && searchData.type !== 'allPages'
                  && searchData.type !== 'metis'
                  && searchData.type !== 'space'
                  && searchData.data.length > 0
                  && (
                    <>
                      {searchData.data.map((item, index) => (
                        <React.Fragment key={index}>
                          <SearchItem
                            position={index}
                            data={item}
                            callback={searchClickCallBack}
                            api={api}
                            wrapClass={cx('item-wrap')}
                          />
                        </React.Fragment>

                      ))}
                    </>
                  )}
                {!hasMore && !loading && !!total && <NoMore />}
              </InfiniteScroll>
            )}

            <SpinRender loading={loading} />
            {!loading
              && searchData.data.length + metisData.data.length + spaceData.data.length === 0 && (
                <EmptyRender />
            )}

          </>
        </div>

      )}
    </div>
  );
};

export default Result;
